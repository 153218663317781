import { useRef, useState } from "react"
import { getNewTokens } from "../auth"
import { useContext } from "react"
import { LoginContext } from "../App"
import { ThreeDots } from "react-loader-spinner"

export default function Login() {
    
    const usernameField = useRef()
    const passwordField = useRef()

    const [setUserIsLoggedIn, setIsStaff, setAccountName, setFirstAvailableDate, setLastAvailableDate, setIsMainBrand, setSubBrands, setMainBrandName] = useContext(LoginContext)
    
    const [isLoading, setIsLoading] = useState(false)
    const [flashMessage, setFlashMessage] = useState(null)

    const loginClicked = async () => {
        const username = usernameField.current.value
        const password = passwordField.current.value
        
        setIsLoading(true)
        let result
        try {
            setFlashMessage(null)
            result = await getNewTokens(username,password)
        } catch (exception) {
            setFlashMessage(exception)
            setIsLoading(false)
            return
        }
        if (result.is_staff === false) {
            setUserIsLoggedIn(true)
            setIsStaff(false)
            setAccountName(result.account_name)
            setIsMainBrand(result.is_main_brand)
            if (result.is_main_brand === true) {
                setMainBrandName(result.account_name)
                setSubBrands(result.sub_brands)
            }
        }
        setIsStaff(result.is_staff)
        let firstAvailableDate = new Date(result.first_available_date.year, result.first_available_date.month-1, result.first_available_date.day)
        let lastAvailableDate = new Date(result.last_available_date.year, result.last_available_date.month-1, result.last_available_date.day)
        setFirstAvailableDate(firstAvailableDate)
        setLastAvailableDate(lastAvailableDate)
    }
    
    return (
        <div className="h-[100vh] w-[100vw] flex items-center justify-center bg-bgcolor">
            <div className="w-[30rem] gap-5 bg-navcolor rounded-lg relative flex flex-col items-center p-5">                
                <img src='gep_logo.png' className='h-20 select-none' alt="GEP Logo"></img>
                <input type="text" ref={usernameField} className="w-full caret-white text-white outline-0 h-12 bg-transparent border-b text-lg" placeholder="Username"/>
                <input type="password" ref={passwordField} className="w-full caret-white text-white h-12 outline-0 bg-transparent border-b text-lg" placeholder="Password"/>
                <button className=" bg-sky-700 text-white w-20 h-12 flex items-center justify-center rounded-md font-semibold" onClick={loginClicked}>{isLoading ? 
                    <ThreeDots 
                    height="50" 
                    width="50" 
                    radius="9"
                    color="#ffffff" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isLoading}
                    />:
                    <span>Login</span>}
                </button>
                {
                    flashMessage ? <span className="absolute top-[110%] bg-red-100 p-2 text-red-900 rounded-md border-red-500">{flashMessage}</span> : null
                }
            </div>
        </div>
    )
}