import { useEffect, useState } from "react";
import { AiOutlinePoweroff } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { instance } from "../auth";
import { ThreeDots } from "react-loader-spinner";

export default function RemapCredentialsDashboard() {
  const [credentials, setCredentials] = useState(null);
  const [selectedCredentialIndex, setSelectedCredentialIndex] = useState(null);
  const [brands, setBrands] = useState(null);
  const [selectedBrandIndex, setSelectedBrandIndex] = useState(null);

  const navigate = useNavigate();

  const backClicked = () => {
    navigate(-1);
  };

  const logOutClicked = async () => {
    const response = await instance.post("/auth/logout");
    if (response.data.success) {
      window.location.reload();
    }
  };

  const handleCredentialClick = (index) => {
    setSelectedCredentialIndex(index);
  };

  const handleBrandClick = (index) => {
    setSelectedBrandIndex(index);
  };

  const isButtonEnabled = selectedCredentialIndex !== null && selectedBrandIndex !== null;

  useEffect(() => {
    const getBrokenCredentials = async () => {
      const response = await instance.get("/broken_credentials");
      const apiCredentials = response.data.broken_credentials;
      const apiBrands = response.data.brands;
      setCredentials(apiCredentials);
      setBrands(apiBrands);
    };
    getBrokenCredentials();
  }, []);

  const handleApiCalls = async () => {
    // Perform API calls using selectedCredentialIndex and selectedBrandIndex
    const username = credentials[selectedCredentialIndex].username
    const account_id = brands[selectedBrandIndex].id;
    const response = await instance.post("/match_credentials", {"username":username, "account_id": account_id})
    if (response.data.status === "success") {
        // Remove selected elements from state
        setCredentials(prevCredentials => prevCredentials.filter((_, index) => index !== selectedCredentialIndex));
        setBrands(prevBrands => prevBrands.filter((_, index) => index !== selectedBrandIndex));
  
        // Reset selected indices
        setSelectedCredentialIndex(null);
        setSelectedBrandIndex(null);
      }
  };

  return (
    <div className="bg-bgcolor h-[100vh] w-[100vw] flex flex-col">
        <div className="w-full h-14 bg-navcolor flex flex-shrink-0 justify-between px-3 items-center">
            <div className="flex cursor-pointer items-center" onClick={backClicked}>
            <IoIosArrowBack size={32} className="text-white cursor-pointer" />
            <span className="text-2xl text-white">Admin page</span>
            </div>
            <AiOutlinePoweroff size={32} className="text-white cursor-pointer" onClick={logOutClicked} />
        </div>
        <div className="w-[100vw] pt-10 flex-col items-center flex-grow flex justify-top">
            <div className="w-[80%] p-2 flex gap-2 items-center justify-between">
                <div className="flex gap-2 items-center">
                    <span className="text-3xl font-extrabold text-white p-2">Remap Credentials</span>
                </div>
                </div>
                <div className="w-[80%] h-min flex gap-2">
                <div className="w-1/2 flex flex-col">
                    <div className="h-[43rem] overflow-auto bg-white rounded-lg">
                    <table className="w-full table-fixed border-collapse">
                        <thead className="sticky top-0 bg-white">
                        <tr>
                            <th className="h-10 whitespace-nowrap min-w-[20rem] w-[20rem] text-left text-bgcolor border-b pl-2">
                            Username
                            </th>
                            <th className="h-10 whitespace-nowrap min-w-[20rem] w-[20rem] text-left text-bgcolor border-b pr-2">
                            Old Brand Name
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {credentials !== null ? (
                            credentials.map((account, index) => (
                            <tr
                                className={`h-10 hover:bg-blue-200 cursor-pointer select-none border-b ${
                                index === selectedCredentialIndex ? "bg-blue-200" : ""
                                }`}
                                key={index}
                                onClick={() => handleCredentialClick(index)}
                            >
                                <td className="h-10">
                                <div className="h-10 min-w-[20rem] pl-2 w-[20rem] flex items-center">{account.username}</div>
                                </td>
                                <td className="h-10">
                                <div className="h-10 min-w-[20rem] pr-2 w-[20rem] flex items-center">{account.account_name}</div>
                                </td>
                            </tr>
                            ))
                        ) : (
                            <tr>
                            <td colSpan={2} className="bg-white h-10">
                                <div className="w-full flex items-center justify-center">
                                <ThreeDots width={60} height={38} />
                                </div>
                            </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
                </div>

                <div className="w-1/2 flex flex-col">
                    <div className="h-[43rem] overflow-auto bg-white rounded-lg">
                    <table className="w-full table-fixed border-collapse">
                        <thead className="sticky top-0 bg-white">
                        <tr>
                            <th className="h-10 whitespace-nowrap min-w-[20rem] w-[20rem] text-left text-bgcolor border-b px-2">
                            Brand Name
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {brands !== null ? (
                            brands.map((brand, index) => (
                            <tr
                                className={`h-10 hover:bg-blue-200 cursor-pointer select-none border-b ${
                                index === selectedBrandIndex ? "bg-blue-200" : ""
                                }`}
                                key={index}
                                onClick={() => handleBrandClick(index)}
                            >
                                <td className="h-10">
                                <div className="h-10 px-2 flex items-center">{brand.brand_name}</div>
                                </td>
                            </tr>
                            ))
                        ) : (
                            <tr>
                            <td colSpan={1} className="bg-white h-10">
                                <div className="w-full flex items-center justify-center">
                                <ThreeDots width={60} height={38} />
                                </div>
                            </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
            <div className="w-[80%] py-2 h-16 flex flex-row-reverse">
                <button
                className={`px-4 py-2 bg-blue-500 text-white rounded-md font-bold ${
                    isButtonEnabled ? "" : "opacity-50 cursor-not-allowed"
                }`}
                disabled={!isButtonEnabled}
                onClick={handleApiCalls}
                >
                Match Credentials
                </button>
            </div>
        </div>
    </div>
  );
}