import { useEffect, useState } from "react";
import DashboardChart, {DASHBOARD_CHART_TYPE} from "./DashboardChart"

export default function SponsoredSalesPercentageWidget(props) {
    useEffect(() => {
        if(props.salesData == null) {
            return
        }
        
        const sponsoredSalesPercentageTotal = props.salesData.summary.sponsored_sales_percentage_total
        const sponsoredSalesPercentageTotalPrevPeriod = props.salesData.summary.sponsored_sales_percentage_total_prev_period
        const sponsoredSalesPercentageTotalPrevYear = props.salesData.summary.sponsored_sales_percentage_total_prev_year

        const deltaPrevPeriod = sponsoredSalesPercentageTotalPrevPeriod == null ? null : (sponsoredSalesPercentageTotal-sponsoredSalesPercentageTotalPrevPeriod)/sponsoredSalesPercentageTotalPrevPeriod
        const deltaPrevYear = sponsoredSalesPercentageTotalPrevYear == null ? null : (sponsoredSalesPercentageTotal-sponsoredSalesPercentageTotalPrevYear)/sponsoredSalesPercentageTotalPrevYear

        setSponsoredSalesPercentageTotal(sponsoredSalesPercentageTotal)
        setDeltaPrevPeriod(deltaPrevPeriod)
        setDeltaPrevYear(deltaPrevYear)
    }, [props.salesData])
    
    const [sponsoredSalesPercentageTotal, setSponsoredSalesPercentageTotal] = useState(null)
    const [deltaPrevYear, setDeltaPrevYear] = useState(null)
    const [deltaPrevPeriod, setDeltaPrevPeriod] = useState(null)

    const getDeltaString = (percentage) => {
        if (percentage == null) {
            return "--"
        }
        let sign = "+"
        if (percentage < 0){
            sign = "" // Minus sign is added by the toFixed method
        }
        return `${sign}${(percentage*100).toFixed(2)}%`
    }

    return (
        <div className={`bg-white w-1/2 h-[300px] flex flex-col items-center justify-between p-2 rounded-md shadow-md`}>
            <div className="flex flex-col w-full">
                <span className='text-sm font-light'>SPONSORED SALES %</span>
                <span className='text-4xl pb-2'>{sponsoredSalesPercentageTotal ? `${sponsoredSalesPercentageTotal.toFixed(2)}%` : "--" }</span>
            </div>
            <div className="flex-grow w-full">
                {
                   (props.salesData != null && props.salesData.summary.no_data === true) || (props.salesData != null && props.salesData.summary.sponsored_sales_percentage_total === null) ?
                   <div className="bg-gray-100 flex items-center justify-center h-full text-gray-400 font-semibold">No data to display</div> :
                    <DashboardChart salesData={props.salesData} type={DASHBOARD_CHART_TYPE.SPONSORED_SALES_PERCENTAGE} aggregation={props.aggregation}/>
                }
            </div>
            <div className='flex justify-between w-full px-2'>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-sm font-light'>Prev. Period</div>
                    <span className={`text-sm ${deltaPrevPeriod == null ? null : deltaPrevPeriod > 0 ? "text-green-600" : "text-red-600"}`}>{getDeltaString(deltaPrevPeriod)}</span>
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-sm font-light'>Prev. Year</div>
                    <span className={`text-sm ${deltaPrevYear == null ? null : deltaPrevYear > 0 ? "text-green-600" : "text-red-600"}`}>{getDeltaString(deltaPrevYear)}</span>
                </div>
            </div>
        </div>
    )
}