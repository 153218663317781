import React from "react";
import SalesWidget from './SalesWidget';
import UnitsWidget from './UnitsWidget';
import PageViewsWidget from './PageViewsWidget';
import BuyboxPercentageWidget from './BuyboxPercentageWidget';
import SalesBySkuWidget from './SalesBySkuWidget';
import UnitsOrderedBySkuWidget from './UnitsOrderedBySkuWidget';
import BuyboxPercentageBySkuWidget from './BuyboxPercentageBySkuWidget';
import { ThreeDots } from 'react-loader-spinner';
import Licence from '../Licence';
import SponsoredSalesWidget from './SponsoredSalesWidget';
import SponsoredSalesPercentageWidget from './SponsoredSalesPercentageWidget';
import SegmentedControl from './SegmentedControl';
import {IoIosArrowDown} from 'react-icons/io'
import {AiOutlinePoweroff} from 'react-icons/ai'
import DateRangePicker from './DateRangePicker';
import { useEffect, useState, useRef, useContext } from 'react';
import { instance } from "../auth";
import { AppContext, LoginContext } from "../App";
import { useNavigate } from "react-router-dom";
import SalesByBrandWidget from "./SalesByBrandWidget";


export const AGGREGATION_LEVEL = {
    DAILY: 0,
    WEEKLY: 1,
    MONTHLY: 2,
    YEARLY: 3
}

export default function Dashboard(props) {
    // eslint-disable-next-line
    const [setUserIsLoggedIn, setIsStaff, setAccountName, setFirstAvailableDate, setLastAvailableDate] = useContext(LoginContext)
    const [firstAvailableDate, lastAvailableDate, accountName, isStaff, accountsList, setAccountsList, isMainBrand, subBrands, setSubBrands, mainBrandName] = useContext(AppContext)
    // Aggregation level

    const AVAILABLE_AGGREGATIONS = [
        {value: AGGREGATION_LEVEL.DAILY, label: "Daily"},
        {value: AGGREGATION_LEVEL.WEEKLY, label: "Weekly"},
        {value: AGGREGATION_LEVEL.MONTHLY, label: "Monthly"},
        {value: AGGREGATION_LEVEL.YEARLY, label: "Yearly"}

    ]
    const firstAggregationUpdate = useRef(true);
    const [aggregation, setAggregation] = useState(AGGREGATION_LEVEL.DAILY)

    useEffect(()=>{
        if (firstAggregationUpdate.current === true) {
        firstAggregationUpdate.current = false
        return
        }
        console.log(`Aggregation level: ${aggregation}`)
    }, [aggregation])
    
    const aggregationLevelClicked = (selectedAggregationLevel) => {
        setAggregation(parseInt(selectedAggregationLevel))
    }

    // When true, shows the date range pciker
    const [showRangePicker, setShowRangePicker] = useState(false)
    
    // Click handler for When the user clicks the dates (shows the date range pciker)
    const datesClicked = () => {
        setShowRangePicker(!showRangePicker)
    }

    const dateRangePickerRuestsClosing = () => {
        setShowRangePicker(false)
    }

    const getFirstDayOfTheMonth = () => {
        const year = lastAvailableDate.getFullYear()
        const month = lastAvailableDate.getMonth()
        const day = new Date(year,month,1)
        return day;
    }

    // These states variable hold the dates selected by the user
    const [firstDate, setFirstDate] = useState(null)
    const [secondDate, setSecondDate] = useState(null)

    // eslint-disable-next-line
    useEffect(() => {
        if (firstAvailableDate != null && lastAvailableDate != null && isStaff !== null){
          const firstDay = getFirstDayOfTheMonth()
          setFirstDate(firstDay)
          setSecondDate(lastAvailableDate)
          getData(firstDay, lastAvailableDate)
        }
        // eslint-disable-next-line
    }, [firstAvailableDate, lastAvailableDate])

    const options = {
        month: "short",
        day: "numeric",
        year: "numeric"
    }

    // Handles when the new dates are selected in the range picker
    const handleNewDatesSelected = async (firstDate, secondDate) => {
        setShowRangePicker(false)
        setFirstDate(firstDate)
        setSecondDate(secondDate)
        getData(firstDate,secondDate)
    }

    const getData = async (firstDate, secondDate) => {
        setIsLoading(true)
        const params = {
          "start_date": {
              year: firstDate.getFullYear(),
              month: firstDate.getMonth()+1,
              day: firstDate.getDate()},
          "end_date": {
              year: secondDate.getFullYear(),
              month: secondDate.getMonth()+1,
              day: secondDate.getDate()
          }
        }
        if (isMainBrand === true) {
          if (subBrands === null) {
            return
          }
          let firstSubBrand = subBrands[0]
          if (firstSubBrand.id !== "ALL") {
            return
          }
          let selectedSubBrandId = null
          for (let subBrand of subBrands) {
            if (subBrand.isSelected === true) {
              selectedSubBrandId = subBrand.id
              break
            }
          }
          params["sub_brand_id"] = selectedSubBrandId
        }

        if (isStaff === true) {
          let selectedAccountId = null
          for (let account of accountsList) {
              if (account.isSelected === true) {
                selectedAccountId = account.account_id
                break
              }
          }
          params["account_id"] = selectedAccountId
        }
        const response = await instance.get("/sales", {
          params:  params
        })
        setSalesData(response.data)
    }

    const [salesData, setSalesData] = useState(null)

    useEffect(()=> {
        if (salesData == null) {
        return
        }
        setIsLoading(false)
    },[salesData])

    // Handles the selection of a specific account
    const selectedAccountChanged = (event) => {
        const target = event.target
        const selectedIndex = target.selectedIndex
        if (isStaff === true) {
          setAccountsList((prevState)=>{
            return prevState.map((element, index)=>{
                if (index === selectedIndex) {
                return {...element, isSelected: true}
                } else {
                return {...element, isSelected: false}
                }
            })
            })
        } else {
          setSubBrands((prevState)=>{
            return prevState.map((element, index)=>{
                if (index === selectedIndex) {
                return {...element, isSelected: true}
                } else {
                return {...element, isSelected: false}
                }
            })
            })
        }
        
    }

    // Button to launch the api call
    const navigate = useNavigate();
    const launchAPICall = async () => {
        navigate("/admin")
    }
    
    useEffect(()=>{
        if (isStaff === true && accountsList === null) {
          return
        }
        if (firstDate === null || secondDate === null) {
          return
        }
        if (isMainBrand === true && subBrands !== null) {
          let firstSubBrand = subBrands[0]
          if (firstSubBrand.id !== "ALL") {
            return
          }
        }

        let correctAccountsList = isStaff === true ? accountsList : subBrands
        let selectedAccount = null
        for (let account of correctAccountsList) {
          if (account.isSelected === true) {
            selectedAccount = account
            break
          }
          
        }
        
        let accountName = isStaff === true ? selectedAccount.account_name : selectedAccount.brand_name
        
        getData(firstDate,secondDate)
        setAccountName(accountName)
        //eslint-disable-next-line
    }, [accountsList, subBrands])


    const [isLoading, setIsLoading] = useState(true)

    const [showLicenceModal, setShowLicenceModal] = useState(false)
    const licenceModalRequestsClosing = (ì) => {
        setShowLicenceModal(false)
    }

    const logOutClicked = async () => {
        const response = await instance.post("/auth/logout") 
        if (response.data.success) {
          window.location.reload()
        }
      }
      
    return (
        <div className="bg-bgcolor h-[100vh] w-[100vw] flex flex-col">
        <div className='w-full h-16 bg-navcolor flex justify-between px-3 items-center'>
          <div className='w-1/3'>
            <img src='gep_logo.png' className='h-14 select-none' alt="GEP Logo"></img>
          </div>
          <div className='w-1/3 flex items-center justify-center'>
            <div className='relative w-96 h-8 bg-dropdown rounded-md text-white flex items-center justify-between text-sm px-2' onClick={datesClicked}>
              <span className='cursor-pointer select-none'>{`${firstDate ? firstDate.toLocaleDateString("en-US",options) : null} | ${secondDate ? secondDate.toLocaleDateString("en-US",options) : null}`}</span>
              <IoIosArrowDown />
              <DateRangePicker isVisible={showRangePicker} className="absolute top-full left-1/2 -translate-x-1/2" handleNewDatesSelected={handleNewDatesSelected} dateRangePickerRuestsClosing={dateRangePickerRuestsClosing} firstDate={firstDate} secondDate={secondDate} firstAvailableDate={firstAvailableDate} lastAvailableDate={lastAvailableDate}/>
            </div>
          </div>
          <div className='w-1/3 flex justify-end'>
            <div className='flex gap-2'>
              {
                isStaff && accountsList ? 
                <select name="accounts" id="account" className='bg-dropdown rounded-md text-white flex items-center outline-none ring-0 ring-offset-0 outline-offset-0 justify-between text-sm px-2 cursor-pointer' onChange={selectedAccountChanged} defaultValue={"ALL"}>
                  {
                    accountsList.map((account, index)=> {
                      return <option key={index} value={account.account_id} selected={account.isSelected}>{account.account_name}</option>
                    })
                  }
                </select>
                : isMainBrand && subBrands ? 
                <select name="accounts" id="account" className='bg-dropdown rounded-md text-white flex items-center outline-none ring-0 ring-offset-0 outline-offset-0 justify-between text-sm px-2 cursor-pointer' onChange={selectedAccountChanged} defaultValue={"ALL"}>
                  {
                    subBrands.map((subBrand, index)=> {
                      return <option key={index} value={subBrand.id} selected={subBrand.isSelected}>{subBrand.brand_name}</option>
                    })
                  }
                </select>: null
              }
              <AiOutlinePoweroff size={32} className="text-white cursor-pointer" onClick={logOutClicked}/>
            </div>
          </div>
        </div>
        <div className='w-[100vw] pt-10 flex-col items-center flex-grow flex justify-center'>
          <div className='p-2 flex gap-2 items-center justify-between w-[80%]'>
            <div className='flex gap-2 items-center'>
              {isMainBrand === true && mainBrandName !== null ? 
                <span className='text-3xl font-extrabold text-white p-2'>{mainBrandName} - {accountName}</span> :
                <span className='text-3xl font-extrabold text-white p-2'>{accountName}</span>
              } 
              <ThreeDots 
                height="50" 
                width="50" 
                radius="9"
                color="#ffffff" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={isLoading}
                />
            </div>
            <div className=" flex-grow"></div>
            { isStaff ? <a className="text-white text-xl font-lightd decoration-1 underline underline-offset-4 px-1 cursor-pointer" onClick={launchAPICall}>Admin Page</a> : null }
            <SegmentedControl options={AVAILABLE_AGGREGATIONS} handleOptionClick={aggregationLevelClicked} selectedOption={aggregation} />
          </div>
          <div className='w-[80%] h-min flex flex-col gap-2 flex-grow-0 bg-white rounded-lg p-2'>
            <SalesWidget salesData={salesData} fullWidth={true} aggregation={aggregation} />
            <div className='w-full flex justify-between gap-2 overflow-auto pb-2'>
              <UnitsWidget salesData={salesData} aggregation={aggregation} />
              <PageViewsWidget salesData={salesData} aggregation={aggregation} />
              <BuyboxPercentageWidget salesData={salesData} aggregation={aggregation} />
            </div>
            <div className='w-full flex justify-between gap-2 overflow-auto pb-2'>
              <SponsoredSalesWidget salesData={salesData} aggregation={aggregation} />
              <SponsoredSalesPercentageWidget salesData={salesData} aggregation={aggregation} />
            </div>
            <div className='w-full flex flex-col items-start gap-2'>
              { isStaff === true ? <SalesByBrandWidget salesData={salesData} aggregation={aggregation} /> : null}
              <SalesBySkuWidget salesData={salesData} aggregation={aggregation} />
              <UnitsOrderedBySkuWidget salesData={salesData} aggregation={aggregation} />
            </div>
            <BuyboxPercentageBySkuWidget salesData={salesData} aggregation={aggregation} />
          </div>
        </div>
        <button onClick={()=>{setShowLicenceModal(true)}} className='w-full p-2 text-gray-500 text-xs flex items-center justify-center underline cursor-pointer hover:text-gray-400'>
        About GEP Dashboard
        </button>
        {
          showLicenceModal ? <Licence licenceModalRequestsClosing={licenceModalRequestsClosing} /> : null
        }
      </div>
    )
}