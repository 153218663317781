import { useEffect, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { CategoryScale, Chart, ArcElement, Tooltip, Filler } from "chart.js";
import { COLOR_PALLETE, INVERTED_COLOR_PALLETE, SHUFFLED_COLOR_PALLETE } from './ColorPallete';
Chart.register(CategoryScale);
Chart.register(ArcElement)
Chart.register(Tooltip)
Chart.register(Filler)

export const DASHBOARD_PIE_CHART_TYPE = {
  SALES: 0,
  UNITS: 1,
  BB_PERCENTAGE: 2,
  PAGEVIEWS: 3,
  SALES_BY_BRAND: 4
}
export default function DashboardPieChart(props) {

  const detailListKey = useRef()
  const amountsKey = useRef()
  const labelName = useRef()
  useEffect(()=> {
    if (props.salesData === null) {
      return
    }

    if (props.salesData != null && props.salesData.summary.no_data === true) {
      return
    }

    if (props.type === DASHBOARD_PIE_CHART_TYPE.SALES) {
      amountsKey.current = "amount"
      detailListKey.current = "sales_detail_by_sku"
      labelName.current = "sales"
    } else if (props.type === DASHBOARD_PIE_CHART_TYPE.UNITS) {
      amountsKey.current = "units"
      detailListKey.current = "units_by_sku"
      labelName.current = "units"
    } else if (props.type === DASHBOARD_PIE_CHART_TYPE.BB_PERCENTAGE) {
      amountsKey.current = "buybox_percentage"
      detailListKey.current = "buybox_percentage_by_sku"
      labelName.current = "buybox percentage"
    } else if (props.type === DASHBOARD_PIE_CHART_TYPE.PAGEVIEWS) {
      amountsKey.current = "page_views"
      detailListKey.current = "page_views_by_sku"
      labelName.current = "page views"
    } else if (props.type === DASHBOARD_PIE_CHART_TYPE.SALES_BY_BRAND) {
      amountsKey.current = "amount"
      detailListKey.current = "sales_detail_by_brand"
      labelName.current = "sales"
    }
    /*
    amount:
    146465.55
    date:
    'Sun, 01 Jan 2023 00:00:00 GMT'
    */
    const labels = [] // contiene le labels che spuntano nell'hover pop up in grassetto
    const amounts = []
    let others = 0
    for (let i = 0; i < props.salesData[detailListKey.current].length; i++) {
      let data = props.salesData[detailListKey.current][i]
      if (i <= 9) {
        // Aggiunge labels che spuntano nell'hover pop up in grassetto
        if (props.type === DASHBOARD_PIE_CHART_TYPE.SALES_BY_BRAND) {
          labels.push(data.brand) // nel caso in cui il tipo sia SALES_BY_BRAND la label deve essere il nome del brand
        } else {
          labels.push(data.sku) // altrimenti lo sku
        }
        amounts.push(data[amountsKey.current])
      } else {
        others += data[amountsKey.current]
      }
    }

    if (others > 0) {
      labels.push("Others")
      amounts.push(others)
    }
    
    setSalesDates(labels)
    setSalesAmounts(amounts)
  }, [props.salesData, props.type])

  
  const [salesAmounts, setSalesAmounts] = useState(null)
  const [salesDates, setSalesDates] = useState(null)

  let colorPallette = COLOR_PALLETE
  if (props.invertedPallette === true) {
    colorPallette = INVERTED_COLOR_PALLETE
  } else if (props.shuffledPallette === true) {
    colorPallette = SHUFFLED_COLOR_PALLETE
  }

  return (
      <div className={`flex bg-white items-center justify-center m-5 w-[250px] h-[250px]`}>
          
          {
            salesAmounts !== null && salesDates !== null ? 
            <Pie
            datasetIdKey='id'
            options={{
              responsive:true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  grid: {
                    display: false,
                    color: "rgba(0,0,0,0)",
                  },
                  display: false, // hides the y axis
                  suggestedMin: Math.max(0, Math.min(...salesAmounts) - (Math.max(...salesAmounts) - Math.min(...salesAmounts))/10*2),
                  suggestedMax: Math.max(...salesAmounts) + (Math.max(...salesAmounts) - Math.min(...salesAmounts))/10*2,
                  ticks: {
                    display: false
                  },
                  offset: false
                },
                x: {
                  grid: {
                    display: false,
                    color: "rgba(0,0,0,0)"
                  },
                  display: false,
                  offset: false,
                }
              }
            }}
            data={{
              labels: salesDates,
              datasets: [
                {
                  id: 1,
                  label: labelName.current,
                  labels: salesDates,
                  data: salesAmounts,
                  borderColor: "rgba(255,255,255)",
                  pointRadius: salesDates.length > 30 ? 0: 1,
                  fill: true,
                  backgroundColor: colorPallette,
                  borderWidth:1
                },
              ],
            }}
            /> 
            : null
          }
          
      </div>
  )
}