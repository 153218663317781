import axios from "axios";

let baseURL = null
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    baseURL = "http://localhost:5003/"
} else {
    // production code
    baseURL = 'https://globalecompartnersapi.com:8443/GEPClientsDashboardAPI/'
}

export const instance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
    baseURL: baseURL
});



function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

instance.interceptors.request.use(
    (config) => {
        return {...config, headers:{...config.headers, "X-CSRF-TOKEN": getCookie("csrf_access_token")}, withCredentials:true, }
    },
    (error) => {
      return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    (res) => {
        return res;
    }, async (err) => {
    const originalConfig = err.config;

    if (err.response) {
    // Access Token was expired
    if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
        const token = await refreshToken();
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        return instance(originalConfig);
        } catch (_error) {
        if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
        }

        return Promise.reject(_error);
        }
    }

    if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
    }
    }

    return Promise.reject(err);
});


export async function refreshToken() {
    try {
        let response = await fetch(baseURL+"auth/refresh", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          "X-CSRF-TOKEN": getCookie("csrf_refresh_token"),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
        let data = await response.json()
        if (data.refresh === true){
            return data
        }
    } catch (e) {
        return false
    }
    
    
  }

  export async function getNewTokens(username, password, rememberMe = false) {
    let data = {
        'username': username,
        'password': password,
        'remember_me': rememberMe
        }
    try {
        let response = await fetch(baseURL+"auth/login", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        mode: 'cors', // no-cors, *cors, same-origin
        
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        
        let responseData = await response.json()
        if (responseData.success === true) {
            return responseData
        } else {
            throw(responseData.message)
        }
    } catch (e) {
        throw(e)
    }
    
}