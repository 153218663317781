import './App.css';

import { createContext, useEffect, useRef, useState } from 'react';
import Login from './components/Login';
import { instance, refreshToken } from './auth';
import { Route, Routes, HashRouter } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import CredentiaslDashboard from './components/CredentialsDashboard';
import Admin from './components/Admin';
import MappingsDashboard from './components/MappingsDashboard';
import RemapCredentialsDashboard from './components/RemapCredentialsDashboard';

export const LoginContext = createContext()
export const AppContext = createContext()


function App() {


  // AUTH
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(null)
  
  useEffect(()=>{
    const refreshTokens = async () => {

      // effettua il refresh del token
      const result = await refreshToken()
      
      // se non è loggato, imposta come falso lo state userIsLoggedIn
      if (result === false) {
        setUserIsLoggedIn(result)
      } else {
        // se è loggato e non è staff imposta userIsLoggedIn su true
        if (result.is_staff === false) {
          setUserIsLoggedIn(true)
          setIsStaff(false)
          setAccountName(result.account_name)
          setIsMainBrand(result.is_main_brand)
          if (result.is_main_brand === true) {
            setSubBrands(result.sub_brands)
            setMainBrandName(result.account_name)
          }
        }
        // se è loggato ed è staff impostiamo lo stato di isStaff su true
        // però non indichiamo userIsLoggedIn su true perché prima dobbiamo ottenere
        // dal server la lista di tutti gli account per cui effettuaremo questa richiesta
        // al server nell'useEffect che ha come dipendenza lo stato isStaff
        setIsStaff(result.is_staff)
        let resultFirstAvailableDate = new Date(result.first_available_date.year, result.first_available_date.month-1, result.first_available_date.day)
        let resultLastAvailableDate = new Date(result.last_available_date.year, result.last_available_date.month-1, result.last_available_date.day)
        setFirstAvailableDate(resultFirstAvailableDate)
        setLastAvailableDate(resultLastAvailableDate)
      }
    }
    refreshTokens()
  }, [])
  const [isMainBrand, setIsMainBrand] = useState(false)
  const [subBrands, setSubBrands] = useState(null)
  const subBrandsAlreadyProcessed = useRef(false)
  useEffect(()=>{
    if (isMainBrand === false || subBrands === null) {
      return
    }
    if (subBrandsAlreadyProcessed.current !== false) {
      return
    }
    const dataWithSeclectedBool = subBrands.map((element)=> {
      return {...element, isSelected: false}
    })
    const subBrandsWithSelectedBool = [{id: "ALL", brand_name: "All", isSelected: true}, ...dataWithSeclectedBool]
    setSubBrands(subBrandsWithSelectedBool)
    setAccountName("All")
    subBrandsAlreadyProcessed.current = true
  }, [isMainBrand, subBrands])

  const [isStaff, setIsStaff] = useState(null)
  const [accountsList, setAccountsList] = useState(null)
  
  useEffect(()=> {
    if (isStaff == null || isStaff === false) {
      return
    }
    const getAccountsList = async () => {
      const result = await instance.get("/accounts")
      const dataWithSeclectedBool = result.data.map((element)=> {
        return {...element, isSelected: false}
      })
      const accountsList = [{account_id: "ALL", account_name: "All", isSelected: true}, ...dataWithSeclectedBool]
      setAccountsList(accountsList)
      setAccountName("All")
      setUserIsLoggedIn(true)
    }
    getAccountsList()
  }, [isStaff])

  const [firstAvailableDate, setFirstAvailableDate] = useState(null)  
  const [lastAvailableDate, setLastAvailableDate] = useState(null)
  const [accountName, setAccountName] = useState(null)
  const [mainBrandName, setMainBrandName] = useState(null)
  
  return (
    userIsLoggedIn === null ? 
    <div className="bg-bgcolor h-[100vh] w-[100vw]"></div> :
    <LoginContext.Provider value={[setUserIsLoggedIn, setIsStaff, setAccountName, setFirstAvailableDate, setLastAvailableDate, setIsMainBrand, setSubBrands, setMainBrandName]}>
    {
      userIsLoggedIn === false ?
      <Login /> :
      <AppContext.Provider value={[firstAvailableDate, lastAvailableDate, accountName, isStaff, accountsList, setAccountsList, isMainBrand, subBrands, setSubBrands, mainBrandName]}>
        <HashRouter basename="/">
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/credentials" element={<CredentiaslDashboard />} />
            <Route path="/admin/mappings" element={<MappingsDashboard />} />
            <Route path="/admin/remap" element={<RemapCredentialsDashboard />} />
          </Routes>
        </HashRouter>
      </AppContext.Provider>
    }
    </LoginContext.Provider>
  );
}

export default App;
