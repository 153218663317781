import { useEffect, useState } from 'react';
import { IoIosArrowUp } from 'react-icons/io';
import { COLOR_PALLETE } from './ColorPallete';
import DashboardPieChart, { DASHBOARD_PIE_CHART_TYPE } from './DashboardPieChart';
export default function SalesBySkuWidget(props) {
    
    const [topTen, setTopTen] = useState()
    const [others, setOthers] = useState()

    const [showOthers, setShowOthers] = useState(false)
    useEffect(()=>{
        if (props.salesData == null) {
            return
        }
        if (props.salesData != null && props.salesData.summary.no_data === true) {
            return
        }
        const topTenArray = []
        const othersArray = []
        for (let i = 0; i < props.salesData.sales_detail_by_sku.length ; i++) {
            let element = props.salesData.sales_detail_by_sku[i]
            if (i <= 9) {
                topTenArray.push(element)
            } else {
                othersArray.push(element)
            }
            setTopTen(topTenArray)
            setOthers(othersArray)
        }
    }, [props.salesData])

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const getSquareForIndex =(index) => {
        const style = {
            backgroundColor: COLOR_PALLETE[index]
        }
        return(<div className='w-3 h-3 min-w-[0.75rem] min-h-[0.75rem] border border-white' style={style}></div>)
    }
    return (
        <div className="bg-white w-full flex flex-col items-start justify-between p-2 rounded-md shadow-md">
            <span className='text-sm font-light'>SALES BY SKU - TOP 100</span>
            {
            props.salesData != null && props.salesData.summary.no_data === true ?
            <div className="bg-gray-100 flex items-center justify-center w-full h-40 text-gray-400 font-semibold">No data to display</div> :
                    
            <div className='w-full flex flex-col lg:flex-row justify-between items-center lg:items-start'>
                <DashboardPieChart salesData={props.salesData} type={DASHBOARD_PIE_CHART_TYPE.SALES}/>
                <div className='flex-grow flex-col flex'>
                    <div className='font-bold text-white bg-bgcolor rounded-md text-xs mb-2 pl-1 select-none'>TOP 10</div>
                    <table className='table-auto w-full mb-2'>
                        <colgroup>
                            <col className=''/>
                            <col className='w-full'/>
                            <col className=''/>
                            <col className=''/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className='text-left text-gray-400 font-extrabold text-xs'>SKU</th>
                                <th className='text-left text-gray-400 font-extrabold text-xs'>Product Name</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4'>Sales</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4'>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                topTen?.map((element, index)=>{
                                    let isOdd = true
                                    if (index % 2 === 0) {
                                        isOdd = false
                                    }
                                    return (
                                        <tr key={index}>
                                            <td className={`text-left  ${!isOdd ? "bg-gray-100" : ""} text-xs font-light`}><div className='inline-flex items-center gap-2 w-24 sm:w-40 md:w-40 xl:w-48'>{getSquareForIndex(index)} {element.sku}</div></td>
                                            <td className={`text-left  ${!isOdd ? "bg-gray-100" : ""} text-xs font-light`}><div className='inline-flex items-center gap-2'>{element.product_name}</div></td>
                                            <td className={`text-right ${!isOdd ? "bg-gray-100" : ""} text-xs px-4 font-light`}>{formatter.format(element.amount)}</td>
                                            <td className={`text-right ${!isOdd ? "bg-gray-100" : ""} text-xs px-4 font-light`}>{(element.amount/props.salesData.summary.sales_total*100).toFixed(2)}%</td>
                                        </tr>
                                    )
                                })
                            }
                            
                        </tbody>
                    </table>
                    <div className='font-bold text-white bg-gray-400 rounded-md text-xs mb-2 px-1 flex justify-between'>
                        <span>OTHERS</span>
                        <IoIosArrowUp size={16} onClick={()=>{setShowOthers(!showOthers)}}  className={`cursor-pointer select-none transition ${ showOthers ? "rotate-180":""}`} />
                    </div>
                    {
                        showOthers ? 
                        <table className='table-auto w-full mb-2'>
                        <colgroup>
                            <col className=''/>
                            <col className='w-full'/>
                            <col className=''/>
                            <col className=''/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className='text-left text-gray-400 font-extrabold text-xs'>SKU</th>
                                <th className='text-left text-gray-400 font-extrabold text-xs'>Product Name</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4'>Sales</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4'>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {   
                                others?.map((element, index)=>{
                                    let isOdd = true
                                    if (index % 2 === 0) {
                                        isOdd = false
                                    }
                                    return (
                                        <tr key={index}>
                                            <td className={`text-left  ${!isOdd ? "bg-gray-100" : ""} text-xs font-light`}><div className='inline-flex items-center gap-2 w-24 sm:w-40 md:w-48'>{element.sku}</div></td>
                                            <td className={`text-left  ${!isOdd ? "bg-gray-100" : ""} text-xs font-light`}><div className='inline-flex items-center gap-2'>{element.product_name}</div></td>
                                            <td className={`text-right ${!isOdd ? "bg-gray-100" : ""} text-xs px-4 font-light`}>{formatter.format(element.amount)}</td>
                                            <td className={`text-right ${!isOdd ? "bg-gray-100" : ""} text-xs px-4 font-light`}>{(element.amount/props.salesData.summary.sales_total*100).toFixed(2)}%</td>
                                        </tr>
                                    )
                                })
                            }
                            
                        </tbody>
                    </table>
                    : null
                    }
                    
                </div>
            </div>
            }
        </div>
    )
}