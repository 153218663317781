import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePoweroff } from "react-icons/ai";
import { instance } from "../auth";
import { RotatingLines, ThreeDots } from "react-loader-spinner";

const FILTER_BUTTON_TYPE = {
    ALL: 0,
    CREDENTIALS: 1,
    NO_CREDENTIALS: 2
}

export default function CredentiaslDashboard() {
    const navigate = useNavigate()
    const backClicked = () => {
        navigate(-1)
    }
    

    const [accounts, setAccounts] = useState(null)
    
    // loads the accounts list from the router in the state
    useEffect(() => {
        const loadAccounts = async () => {
            try {
                const response = await instance.get("/credentials")
                const data = await response.data
                const dataWithVusibulity =[]
                for (let account of data) {
                    account.isVisible = true
                    dataWithVusibulity.push(account)
                }
                setAccounts(dataWithVusibulity)
            } catch (e) {
                if (e.response.status === 401) {
                    navigate("/")
                    return
                }
            }
        }
        loadAccounts()
    }, [navigate])
    
    

    const logOutClicked = async () => {
        const response = await instance.post("/auth/logout") 
        if (response.data.success) {
          window.location.reload()
        }
    }

    // state

    const [showCreateCredentialsModal, setShowCreateCredentialsModal] = useState(false)
    const [createCredentialsChosenAccount, setCreateCredentialsChosenAccount] = useState(null)

    // action handlers for credentials rows

    const createCredentialsClicked = (index) => {
        setCreateCredentialsChosenAccount(accounts[index])
        setShowCreateCredentialsModal(true)
    }
    
    const createCredentialsModalRequestsClosing = (account_id, username) => {
        setCreateCredentialsChosenAccount(null)
        setShowCreateCredentialsModal(false)

        if(account_id && username) {
            // update list accordingly <--- continuare da qui
            setAccounts((prevState) => {
                let newState = []
                for (let account of prevState) {
                    if (account.id === account_id) {
                        account.username = username
                    }
                    newState.push(account)
                }
                return newState
            })
            filterClicked(activeFilter)
        }
    }

    // Filter selector

    const [activeFilter, setActiveFilter] = useState(FILTER_BUTTON_TYPE.ALL)
    const [filtersNumbers, setFiltersNumbers] = useState([0,0,0])

    const filterClicked = (type) => {
        
        setActiveFilter(type)

        setAccounts((prevState)=>{
            let newState = []
            for (let account of prevState){
                if (type === FILTER_BUTTON_TYPE.CREDENTIALS){
                    if (account.username !== null){
                        account.isVisible = true
                    } else {
                        account.isVisible = false
                    }
                } else if (type === FILTER_BUTTON_TYPE.NO_CREDENTIALS){
                    if (account.username === null){
                        account.isVisible = true
                    } else {
                        account.isVisible = false
                    }
                } else {
                    account.isVisible = true
                }
                newState.push(account)
            }
            return newState
        })
        
    }

    // Delete credentials functionality

    const deleteCredentialsClicked = async (account) => {
        setShowConfirmDeleteModal(true)
        setConfermeDeleteModalAccount(account)
    }

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
    const [confermeDeleteModalAccount, setConfermeDeleteModalAccount] = useState(false)

    const deleteModalCancel = () => {
        setShowConfirmDeleteModal(false)
        setConfermeDeleteModalAccount(null)
    }

    const deleteModalConfirm = async(account) => {
        const result = await instance.delete("/admin/credentials",{params: {account_id:account.id}})
        if (result.status === 200) {
            setAccounts((prevState)=>{
                let newState = []
                for (let iterAccount of prevState) {
                    if (iterAccount.id === account.id) {
                        iterAccount.username = null
                    }
                    newState.push(iterAccount)
                }
                return newState
            })
            filterClicked(activeFilter)
        }
        setShowConfirmDeleteModal(false)
        setConfermeDeleteModalAccount(null)
    }

    // Change password functionality 
    const [changeCredentialsAccount, setChangeCredentialsAccount] = useState(null)
    const [showChangeCredentialsModal, setShowChangeCredentialsModal] = useState(null)
    
    const changeCredentialsClicked = async(account) => {
        setChangeCredentialsAccount(account)
        setShowChangeCredentialsModal(account)
    }

    const changeCredentialsModalRequestsClosing = () => {
        setChangeCredentialsAccount(null)
        setShowChangeCredentialsModal(null)
    }

    useEffect(() => {
        if (!accounts) {
            return
        }

        let noOfAll = 0
        let noOfCredentials = 0
        let noOfNoCredentials = 0

        for (let account of accounts) {
            noOfAll++
            if (account.username !== null) {
                noOfCredentials++
            } else {
                noOfNoCredentials++
            }
        }
        setFiltersNumbers([noOfAll, noOfCredentials, noOfNoCredentials])
    }, [accounts])

    return (
        <div className="bg-bgcolor h-[100vh] w-[100vw] flex flex-col">
            <div className='w-full h-14 bg-navcolor flex flex-shrink-0 justify-between px-3 items-center'>
                <div className="flex cursor-pointer items-center" onClick={backClicked}>
                    <IoIosArrowBack size={32} className="text-white cursor-pointer"/>
                    <span className="text-2xl text-white">Admin page</span>
                </div>
                <AiOutlinePoweroff size={32} className="text-white cursor-pointer" onClick={logOutClicked}/>
            </div>
            <div className='w-[100vw] pt-10 flex-col items-center flex-grow flex justify-top'>
                <div className='w-[80%] p-2 flex gap-2 items-center justify-between'>
                    <div className='flex gap-2 items-center'>
                        <span className='text-3xl font-extrabold text-white p-2'>Login Credentials</span> 
                    </div>
                </div>      
                <div className="w-[80%] flex py-4 gap-2">
                        <FilterButton type={FILTER_BUTTON_TYPE.ALL} number={filtersNumbers[0]} activeFilter={activeFilter} filterClicked={filterClicked}/>
                        <FilterButton type={FILTER_BUTTON_TYPE.CREDENTIALS} number={filtersNumbers[1]} activeFilter={activeFilter}  filterClicked={filterClicked}/>
                        <FilterButton type={FILTER_BUTTON_TYPE.NO_CREDENTIALS} number={filtersNumbers[2]} activeFilter={activeFilter} filterClicked={filterClicked}/>
                </div>          
                <div className='w-[80%] h-min flex flex-col gap-2 flex-grow-0 bg-white rounded-lg p-2 overflow-x-auto'>
                    <table className="">
                        <thead>
                            <tr>
                                <th className=" h-10 whitespace-nowrap min-w-[20rem] w-[20rem] text-left text-bgcolor">Brand name</th>
                                <th className=" h-10 whitespace-nowrap min-w-[20rem] w-[20rem] text-left text-bgcolor">Username</th>
                                <th className=" h-10 whitespace-nowrap text-left text-bgcolor">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { accounts ? 
                                accounts.map((account, index) => {
                                    if (account.isVisible === true) {
                                        return <CredentialsRow account={account} index={index} createCredentialsClicked={createCredentialsClicked} changeCredentialsClicked={changeCredentialsClicked} deleteCredentialsClicked={deleteCredentialsClicked}/>
                                    } else {
                                        return null
                                    }
                                }) :
                                <tr>
                                    <td colSpan={3} className="bg-white h-10"><div className="w-full flex items-center justify-center"><ThreeDots width={60} height={38}/></div></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            
            
            { showCreateCredentialsModal && createCredentialsChosenAccount && <CreateCredentialsModal account={createCredentialsChosenAccount} createCredentialsModalRequestsClosing={createCredentialsModalRequestsClosing} /> }
            { showConfirmDeleteModal && confermeDeleteModalAccount && <ConfirmModal account={confermeDeleteModalAccount} onCancel={deleteModalCancel} onConfirm={deleteModalConfirm} /> }
            { showChangeCredentialsModal && changeCredentialsAccount && <ChangeCredentialsModal account={changeCredentialsAccount} changeCredentialsModalRequestsClosing={changeCredentialsModalRequestsClosing} />}
        </div>
        
    )
}

function ConfirmModal({account, onConfirm, onCancel}) {
    const [isLoading, setIsLoading] = useState(false)

    const confirmClicked = () => {
        setIsLoading(true)
        onConfirm(account)
    }
    return (
    <>
        <div className="w-[100vw] h-[100vh] bg-black fixed opacity-60 cursor-pointer" onClick={onCancel}></div>
        <div className="w-[30rem]  bg-white fixed rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-5 flex flex-col gap-4">
            <h1 className=" text-3xl font-bold pt-1">Confirm deletion</h1>
            <span>Are you sure you want to delete the credentials for <b>{account.brand_name}</b>?</span>
            <div className="flex flex-row-reverse gap-2">
                <div className="bg-red-600 rounded-md flex justify-center items-center p-2 w-20 font-bold text-white hover:bg-red-500 cursor-pointer select-none" onClick={confirmClicked}>{ isLoading ? <RotatingLines width={20} strokeColor="white"/> : "Delete"}</div>
                <button className="bg-gray-100 rounded-md p-2 w-20 font-bold text-black border hover:bg-gray-200" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    </>
    )
}

function FilterButton({type, number, activeFilter, filterClicked}) {
    
    const getButtonText = () => {
        if (type === FILTER_BUTTON_TYPE.ALL) {
            return "All"
        } else if (type === FILTER_BUTTON_TYPE.CREDENTIALS) {
            return "With Credentials"
        } else {
            return "No Credentials"
        }
    }
    const getWrapperStyle = () => {
        if (type === activeFilter) {
            return "h-11 bg-white flex items-center rounded-lg py-5 pl-4 pr-5 gap-2"
        } else {
            return "h-11 bg-gray-600 flex items-center rounded-lg py-5 pl-4 pr-5 gap-2 cursor-pointer group hover:bg-sky-600"
        }
    }

    const getCountStyle = () => {
        if (type === activeFilter) {
            return "rounded-md px-1 bg-sky-700 text-white flex items-center justify-center font-bold select-none"
        } else {
            return "rounded-md px-1 bg-gray-500 text-gray-300 flex items-center justify-center select-none group-hover:bg-sky-700 group-hover:text-white"
        }
    }

    const getTextStyle = () => {
        if (type === activeFilter) {
            return "text-lg font-bold select-none"
        } else {
            return "text-lg font-bold text-gray-300 select-none group-hover:text-white"
        }
    }

    const buttonClicked = () => {
        filterClicked(type)
    }

    return(
        <div className={getWrapperStyle()} onClick={buttonClicked}>
            <div className={getCountStyle()}>{number}</div><div className={getTextStyle()}>{getButtonText()}</div>
        </div>
    )
}

function CredentialsRow({account, index, createCredentialsClicked, changeCredentialsClicked, deleteCredentialsClicked}) {
    const handleCreateCredentialsCLicked = () => {
        createCredentialsClicked(index)
    }

    const handleChangeCredentialsClicked = () => {
        changeCredentialsClicked(account)
    }

    const handleDeleteCredentialsClicked =()=> {
        deleteCredentialsClicked(account)
    }
    return (
    <tr key={account.id}>
        <td className=" h-10 whitespace-nowrap min-w-[20rem] max-w-[20rem] text-left">{account.brand_name}</td>
        <td className={`h-10 whitespace-nowrap min-w-[20rem] max-w-[20rem] text-left ${account.username === null ? "text-gray-400" : null}`}>{account.username ?? "No credentials for brand"}</td>
        <td className=" h-10 whitespace-nowrap text-left">
            {
                account.username ? 
                //<div><button className="hover:text-sky-800 underline decoration-1">Change password</button> | <button className="hover:text-sky-800 underline decoration-1">Change username</button> | <button className="hover:text-sky-800 underline decoration-1" onClick={handleDeleteCredentialsClicked}>Delete credentials</button></div> :
                <div className="flex items-center gap-2"><button className="rounded-md border border-green-800 text-green-800 font-bold p-1 hover hover:bg-green-800 hover:text-white" onClick={handleChangeCredentialsClicked}>Change credentials</button><button className="rounded-md border border-red-800 text-red-800 font-bold p-1 hover hover:bg-red-800 hover:text-white" onClick={handleDeleteCredentialsClicked}>Delete credentials</button></div> :
                <button className="rounded-md border border-sky-600 text-sky-600 font-bold p-1 hover hover:bg-sky-600 hover:text-white" onClick={handleCreateCredentialsCLicked}>Create credentials</button>
            }</td>
    </tr>
    )
} 

function CreateCredentialsModal({account, createCredentialsModalRequestsClosing}) {
    const backdropOrCancelClicked = () => {
        createCredentialsModalRequestsClosing()
    }

    // Username
    const [usernameFieldText, setUsernameFieldText] = useState("")
    
    useEffect(() => {
        if (!account) {
            return
        }
        const noSpaceUsername = account.brand_name.replace(/\s+/g, '').toLowerCase();
        setUsernameFieldText(noSpaceUsername)
    }, [account])

    const usernameFieldChanged = (event) => {
        const target = event.target
        const value = target.value
        setPasswordFieldText(value)
    }

    // Password

    const [passwordFieldText, setPasswordFieldText] = useState("")

    const generateRandomPasswordClicked = () => {
        const newPassword = generatePassword()
        setPasswordFieldText(newPassword)
    }

    const passwordFieldChanged = (event) => {
        const target = event.target
        const value = target.value
        setPasswordFieldText(value)
    }
    const generatePassword = () => {
        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }
        
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890?:.-_#!$%&/=?<>"
        let password = ""
        
        for (let i = 0; i < 16; i++) {
            const randIndex = getRandomInt(characters.length)
            const randChar = characters.charAt(randIndex)
            password += randChar
        }

        return password
    }

    // Save
    const [isLoading, setIsLoading] = useState(false)
    const saveClicked = async () => {
        if (usernameFieldText.length <= 3 ) {
            alert("The username must be at least 3 characters long")
            return
        }
        if (passwordFieldText.length <= 8 ) {
            alert("The password must be at least 3 characters long")
            return
        }
        setIsLoading(true)
        let accountId = account.id
        let response = await instance.post("/admin/credentials", {
            account_id: accountId,
            brand_name: account.brand_name,
            username: usernameFieldText,
            password: passwordFieldText
        })
        if (response.status === 200) {
            createCredentialsModalRequestsClosing(account.id, usernameFieldText)
        }
        setIsLoading(false)
    }

    
    return (
        <>
            <div className="w-[100vw] h-[100vh] bg-black fixed opacity-60 cursor-pointer" onClick={backdropOrCancelClicked}></div>
            <div className="w-[30rem]  bg-white fixed rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-5">
                <span className=" font-extrabold text-slate-400">CREATE CREDENTIALS FOR</span>
                <h1 className=" text-3xl font-bold pt-1 pb-2">{account.brand_name}</h1>
                <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex justify-end">
                    </div>
                    <input className="w-full border border-gray-400 rounded-md px-2 h-9" type="text" placeholder="Username" value={usernameFieldText} onChange={usernameFieldChanged} />
                    <input className="w-full border border-gray-400 rounded-md px-2 h-9" type="text" placeholder="Password" value={passwordFieldText} onChange={passwordFieldChanged} />
                    <button className="bg-gray-100 rounded-md p-2 font-bold text-black border h-9 text-sm hover:bg-gray-200" onClick={generateRandomPasswordClicked}>Generate Random Password</button>
                    <span className="text-gray-600 text-sm">Caution: Once you click on save, you will not be able to retrieve this password again. Make sure to securely store it in a safe place before proceeding.</span>
                    <div className="flex flex-row-reverse gap-2">
                        <div className="bg-blue-600 rounded-md flex justify-center items-center p-2 w-20 font-bold text-white hover:bg-blue-500 cursor-pointer select-none" onClick={saveClicked}>{ isLoading ? <RotatingLines width={20} strokeColor="white"/> : "Save"}</div>
                        <button className="bg-gray-100 rounded-md p-2 w-20 font-bold text-black border hover:bg-gray-200" onClick={backdropOrCancelClicked}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    )
}

function ChangeCredentialsModal({account, changeCredentialsModalRequestsClosing}) {
    const backdropOrCancelClicked = () => {
        changeCredentialsModalRequestsClosing()
    }

    // Username
    const [usernameFieldText, setUsernameFieldText] = useState("")
    
    useEffect(() => {
        if (!account) {
            return
        }

        setUsernameFieldText(account.username)
    }, [account])

    const usernameFieldChanged = (event) => {
        const target = event.target
        const value = target.value
        setPasswordFieldText(value)
    }

    // Password

    const [passwordFieldText, setPasswordFieldText] = useState("")

    const generateRandomPasswordClicked = () => {
        const newPassword = generatePassword()
        setPasswordFieldText(newPassword)
    }

    const passwordFieldChanged = (event) => {
        const target = event.target
        const value = target.value
        setPasswordFieldText(value)
    }
    const generatePassword = () => {
        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }
        
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890?:.-_#!$%&/=?<>"
        let password = ""
        
        for (let i = 0; i < 16; i++) {
            const randIndex = getRandomInt(characters.length)
            const randChar = characters.charAt(randIndex)
            password += randChar
        }

        return password
    }

    // Save
    const [isLoading, setIsLoading] = useState(false)
    const saveClicked = async () => {
        if (usernameFieldText.length <= 3 ) {
            alert("The username must be at least 3 characters long")
            return
        }
        if (passwordFieldText.length <= 8 ) {
            alert("The password must be at least 3 characters long")
            return
        }
        setIsLoading(true)
        let accountId = account.id
        let payload = {
            account_id: accountId,
            username: usernameFieldText,
            password: passwordFieldText
        }
        let response = await instance.put("/admin/credentials", payload)
        if (response.status === 200) {
            changeCredentialsModalRequestsClosing(account.id, usernameFieldText)
        }
        setIsLoading(false)
    }

    
    return (
        <>
            <div className="w-[100vw] h-[100vh] bg-black fixed opacity-60 cursor-pointer" onClick={backdropOrCancelClicked}></div>
            <div className="w-[30rem]  bg-white fixed rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-5">
                <span className=" font-extrabold text-slate-400">CHANGE CREDENTIALS FOR</span>
                <h1 className=" text-3xl font-bold pt-1 pb-2">{account.brand_name}</h1>
                <div className="w-full flex flex-col gap-2">
                    <div className="w-full flex justify-end">
                    </div>
                    <input className="w-full border border-gray-400 rounded-md px-2 h-9" type="text" placeholder="Username" value={usernameFieldText} onChange={usernameFieldChanged} />
                    <input className="w-full border border-gray-400 rounded-md px-2 h-9" type="text" placeholder="New Password" value={passwordFieldText} onChange={passwordFieldChanged} />
                    <button className="bg-gray-100 rounded-md p-2 font-bold text-black border h-9 text-sm hover:bg-gray-200" onClick={generateRandomPasswordClicked}>Generate Random Password</button>
                    <span className="text-gray-600 text-sm">Caution: Once you click on save, you will not be able to retrieve this password again. Make sure to securely store it in a safe place before proceeding.</span>
                    <div className="flex flex-row-reverse gap-2">
                        <div className="bg-blue-600 rounded-md flex justify-center items-center p-2 w-20 font-bold text-white hover:bg-blue-500 cursor-pointer select-none" onClick={saveClicked}>{ isLoading ? <RotatingLines width={20} strokeColor="white"/> : "Save"}</div>
                        <button className="bg-gray-100 rounded-md p-2 w-20 font-bold text-black border hover:bg-gray-200" onClick={backdropOrCancelClicked}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    )
}