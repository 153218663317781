export const COLOR_PALLETE =
[
    "#182C3D",
    "#B3C7DD",
    "#C08521",
    "#479087",
    "#009BF9",
    "#4D3E2A",
    "#0B6B80",
    "#79415B",
    "#9AADC3",
    "#8FD87B",
    "#008284"
]

export const INVERTED_COLOR_PALLETE = [...COLOR_PALLETE].reverse()

export const SHUFFLED_COLOR_PALLETE = [
    "#479087", //4
    "#182C3D", //1
    "#4D3E2A", //6
    "#C08521", //3
    "#9AADC3", //9
    "#79415B", //8
    "#8FD87B", //10
    "#009BF9", //5
    "#008284", //11
    "#0B6B80", //7
    "#B3C7DD", //2
]