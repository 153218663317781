import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { AiOutlinePoweroff } from "react-icons/ai"
import { instance } from "../auth"
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Admin(props) {
    
    const navigate = useNavigate()

    // EVENT HANDLERS

    const backClicked = () => {
        navigate(-1)
    }
    
    const productMappingsClicked = () => {
        navigate("/admin/mappings")
    }

    const loginCredentialsClicked = async () => {
        navigate("/admin/credentials")
    }

    const remapCredentialsClicked = async () => {
        navigate("/admin/remap")
    }

    const logOutClicked = async () => {
        const response = await instance.post("/auth/logout") 
        if (response.data.success) {
          window.location.reload()
        }
    }

    // STATE
    const AVAILABLE_FUNCTIONS = [
        {
            type: ADMIN_BUTTON_TYPE.PRODUCT_MAPPINGS,
            action: productMappingsClicked,
        },
        {
            type: ADMIN_BUTTON_TYPE.LOGIN_CREDENTIALS,
            action: loginCredentialsClicked,
        },
        {
            type: ADMIN_BUTTON_TYPE.REMAP_CREDENTIALS,
            action: remapCredentialsClicked,
        }
    ]

    const [availableFunctions, _] = useState(AVAILABLE_FUNCTIONS)

    return (
        <div className="bg-bgcolor h-[100vh] w-[100vw] flex flex-col">
            <div className='w-full h-14 bg-navcolor flex justify-between px-3 items-center'>
                <div className="flex cursor-pointer items-center" onClick={backClicked}>
                    <IoIosArrowBack size={32} className="text-white cursor-pointer"/>
                    <span className="text-2xl text-white">Dashboard</span>
                </div>
                <AiOutlinePoweroff size={32} className="text-white cursor-pointer" onClick={logOutClicked}/>
            </div>
            <div className='w-[100vw] pt-10 flex-col items-center flex-grow flex justify-top'>
                <div className='w-[80%] p-2 flex gap-2 items-center justify-between'>
                    <div className='flex gap-2 items-center'>
                        <span className='text-3xl font-extrabold text-white p-2'>Admin Page</span> 
                    </div>
                </div>                
                <div className='w-[80%] h-min flex flex-wrap gap-10 flex-grow-0 p-2'>
                    {
                        availableFunctions.map((button, index) => {
                            return <AdminButton key={index} type={button.type} action={button.action} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const ADMIN_BUTTON_TYPE = {
    PRODUCT_MAPPINGS: 0,
    LOGIN_CREDENTIALS: 1,
    REMAP_CREDENTIALS: 2
}

function AdminButton ({type, action}) {
    const getButtonTitle = () => {
        if (type === ADMIN_BUTTON_TYPE.PRODUCT_MAPPINGS) {
            return "Product Mappings"
        } else if (type === ADMIN_BUTTON_TYPE.LOGIN_CREDENTIALS) {
            return "Login Credentials"
        } else {
            return "Remap Credentials"
        }
    }

    const getButtonDescription = () => {
        if (type === ADMIN_BUTTON_TYPE.PRODUCT_MAPPINGS) {
            return "Get a list of the products being mapped to each brand."
        } else if (type === ADMIN_BUTTON_TYPE.LOGIN_CREDENTIALS) {
            return "Create or reset login credentials for each brand."
        } else {
            return "Remap credentials after brand name change."
        }
    }

    return (
        <div className="min-w-[25rem] max-w-[25rem] h-32 flex-grow shrink-0 bg-white rounded-lg p-5 flex items-center cursor-pointer group" onClick={action}>
            <div className="flex flex-col h-full gap-1 w-[90%]">
                <h1 className="text-2xl font-extrabold group-hover:text-sky-800">{getButtonTitle()}</h1>
                <span className=" text-gray-500">{getButtonDescription()}</span>
            </div>
            <IoIosArrowForward size={32} className="group-hover:text-sky-800"/>
        </div>
    )
}