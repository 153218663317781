import { useEffect, useState } from "react";
import DashboardChart, {DASHBOARD_CHART_TYPE} from "./DashboardChart"

export default function SalesWidget(props) {
    useEffect(() => {
        if(props.salesData == null) {
            return
        }
        const salesTotal = props.salesData.summary.sales_total
        const salesTotalPrevPeriod = props.salesData.summary.sales_total_previous_period
        const salesTotalPrevYear = props.salesData.summary.sales_total_previous_year

        const deltaPrevPeriod = salesTotalPrevPeriod == null ? null : (salesTotal-salesTotalPrevPeriod)/salesTotalPrevPeriod
        const deltaPrevYear = salesTotalPrevYear == null ? null : (salesTotal-salesTotalPrevYear)/salesTotalPrevYear

        setSalesTotal(salesTotal)
        setDeltaPrevPeriod(deltaPrevPeriod)
        setDeltaPrevYear(deltaPrevYear)
    }, [props.salesData])

    const [salesTotal, setSalesTotal] = useState(null)
    const [deltaPrevYear, setDeltaPrevYear] = useState(null)
    const [deltaPrevPeriod, setDeltaPrevPeriod] = useState(null)
    
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const getDeltaString = (percentage) => {
        if (percentage == null) {
            return "--"
        }
        let sign = "+"
        if (percentage < 0){
            sign = "" // Minus sign is added by the toFixed method
        }
        return `${sign}${(percentage*100).toFixed(2)}%`
    }

    return (
        <div className={`bg-white ${props.fullWidth ? "w-full" : "w-1/3"} h-[300px] flex flex-col items-center justify-between p-2 rounded-md shadow-md`}>
            <div className="flex flex-col w-full">
                <span className='text-sm font-light'>PRODUCTS SALES</span>
                <span className='text-4xl pb-2'>{salesTotal ? formatter.format(salesTotal) : "--" }</span>
            </div>
            <div className="flex-grow w-full">
                {
                    props.salesData != null && props.salesData.summary.no_data === true ?
                    <div className="bg-gray-100 flex items-center justify-center h-full text-gray-400 font-semibold">No data to display</div> :
                    <DashboardChart salesData={props.salesData} type={DASHBOARD_CHART_TYPE.SALES} aggregation={props.aggregation}/>
                }
            </div>
            <div className='flex justify-between w-full px-2'>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-sm font-light'>Prev. Period</div>
                    <span className={`text-sm ${deltaPrevPeriod == null ? null : deltaPrevPeriod > 0 ? "text-green-600" : "text-red-600"}`}>{getDeltaString(deltaPrevPeriod)}</span>
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-sm font-light'>Prev. Year</div>
                    <span className={`text-sm ${deltaPrevYear == null ? null : deltaPrevYear > 0 ? "text-green-600" : "text-red-600"}`}>{getDeltaString(deltaPrevYear)}</span>
                </div>
            </div>
        </div>
    )
}