import { Fragment, useEffect, useState } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"


export default function DateRangePicker(props) {

    useEffect(()=> {
        setFirstDate(props.firstDate)
        setSecondDate(props.secondDate)
    }, [props.firstDate, props.secondDate])
    
    useEffect(() => {
        if (props.secondDate === null) {
            return
        }
        // Sets up the dates of the two calendars
        const date = props.secondDate
        setFirstMonth(date.getMonth()+1)
        setFirstYear(date.getFullYear())

        if (date.getMonth() === 11) {
            setSecondMonth(1)
            setSecondYear(date.getFullYear() + 1)
        } else {
            setSecondMonth(date.getMonth() + 2)
            setSecondYear(date.getFullYear())
        }
    }, [props.secondDate])

    // First calendar
    const [firstMonth, setFirstMonth] = useState(null)
    const [firstYear, setFirstYear] = useState(null)
    
    // Second Calendar
    const [secondMonth, setSecondMonth] = useState(null)
    const [secondYear, setSecondYear] = useState(null)

    const prevMonthClicked = () => {
        if (firstMonth === 1) {
            setFirstMonth(12)
            setFirstYear(firstYear - 1)
        } else {
            setFirstMonth(firstMonth - 1)
        }
        if (secondMonth === 1) {
            setSecondMonth(12)
            setSecondYear(secondYear - 1)
        } else {
            setSecondMonth(secondMonth - 1)
        }
        
    }

    const nextMonthClicked = () => {
        if (firstMonth === 12) {
            setFirstMonth(1)
            setFirstYear(firstYear + 1)
        } else {
            setFirstMonth(firstMonth + 1)
        }
        if (secondMonth === 12) {
            setSecondMonth(1)
            setSecondYear(secondYear + 1)
        } else {
            setSecondMonth(secondMonth + 1)
        }
    }
    
    const [firstDate, setFirstDate] = useState(props.firstDate)
    const [secondDate, setSecondDate] = useState(props.secondDate)

    const handleDateClicked = (date) => {
        if (firstDate === null) {
            setFirstDate(date)
        } else {
            if (secondDate === null) {
                if (date.getTime() < firstDate.getTime()) {
                    setFirstDate(date)
                } else {
                    setSecondDate(date)
                    setSelectedPreset(null)
                    props.handleNewDatesSelected(firstDate, date)
                }
            } else {
                setFirstDate(date)
                setSecondDate(null)
            }
        }
    }

    const [selectedPreset, setSelectedPreset] = useState()

    const handlePresetSelected = (preset) => {
        
        if (PRESETS[preset] === PRESETS.YTD) {
            const newFirstDate = new Date(props.lastAvailableDate.getFullYear(), 0, 1)
            setFirstDate(newFirstDate)
            setSecondDate(props.lastAvailableDate)
            setSelectedPreset(PRESETS.YTD)
            props.handleNewDatesSelected(newFirstDate, props.lastAvailableDate)
        } else {
            const numberOfDays = PRESETS[preset]
            const newFirstDate = new Date(props.lastAvailableDate)
            newFirstDate.setDate(newFirstDate.getDate() - numberOfDays)
            setFirstDate(newFirstDate)
            setSecondDate(props.lastAvailableDate)
            setSelectedPreset(numberOfDays)
            props.handleNewDatesSelected(newFirstDate, props.lastAvailableDate)
            
        }
        
    }

    
    return (
        <>
        <div className={`top-[0px] left-[0px] w-[100vw] cursor-pointer h-[100vh] fixed ${props.isVisible ? null : "hidden"}`} onClick={props.dateRangePickerRuestsClosing}>
        </div>
        <div className={`w-[54rem] shadow-md flex overflow-hidden h-72 rounded-md bg-white ${props.className} ${props.isVisible ? null : "hidden"}`} onClick={(e) => {e.stopPropagation()}}>
            <DateSelector position={DATESELECTOR_POSITION.LEFT} month={firstMonth} year={firstYear} prevMonthClicked={prevMonthClicked} nextMonthClicked={nextMonthClicked} handleDateClicked={handleDateClicked} firstDate={firstDate} secondDate={secondDate} firstAvailableDate={props.firstAvailableDate} lastAvailableDate={props.lastAvailableDate}/>
            <DateSelector position={DATESELECTOR_POSITION.RIGHT} month={secondMonth} year={secondYear} prevMonthClicked={prevMonthClicked} nextMonthClicked={nextMonthClicked}  handleDateClicked={handleDateClicked} firstDate={firstDate} secondDate={secondDate} firstAvailableDate={props.firstAvailableDate} lastAvailableDate={props.lastAvailableDate}/>
            <PresetSelector handlePresetSelected={handlePresetSelected} selectedPreset={selectedPreset}/>
        </div>
        
        </>
    )
}

const PRESETS = {
    SEVEN_DAYS : 7,
    FOURTEEN_DAYS : 14,
    THIRTY_DAYS: 30,
    SIXTY_DAYS: 60,
    NINETY_DAYS: 90,
    YTD: 99
}

function PresetSelector(props) {
    
    

    const presetClicked = (event) => {
        const preset = parseInt(event.target.getAttribute("preset"))
        const matchingKey = Object.keys(PRESETS).find(key => PRESETS[key] === preset);
        props.handlePresetSelected(matchingKey)
    }
    return (
        <div className="w-1/3 h-full flex flex-col bg-white p-2">
            <div className="h-12 flex text-black items-center px-2 py-4">
                <div className="flex-grow flex items-center justify-center relative font-bold select-none">
                    <span>Presets</span>
                </div>
            </div>
            <div className="w-full h-full flex flex-col flex-grow text-black justify-between">
                <span className={`h-8 cursor-pointer rounded mx-2 items-center flex border px-2 text-xs font-semibold ${ props.selectedPreset === PRESETS.SEVEN_DAYS ? "text-white bg-black" : "text-gray-900 hover:bg-[#ebf4fa]"}`} preset={PRESETS.SEVEN_DAYS} onClick={presetClicked}>Last 7 days</span>
                <span className={`h-8 cursor-pointer rounded mx-2 items-center flex border px-2 text-xs font-semibold ${ props.selectedPreset === PRESETS.FOURTEEN_DAYS ? "text-white bg-black" : "text-gray-900 hover:bg-[#ebf4fa]"} `} preset={PRESETS.FOURTEEN_DAYS} onClick={presetClicked}>Last 14 days</span>
                <span className={`h-8 cursor-pointer rounded mx-2 items-center flex border px-2 text-xs font-semibold ${ props.selectedPreset === PRESETS.THIRTY_DAYS ? "text-white bg-black" : "text-gray-900 hover:bg-[#ebf4fa]"}`} preset={PRESETS.THIRTY_DAYS} onClick={presetClicked}>Last 30 days</span>
                <span className={`h-8 cursor-pointer rounded mx-2 items-center flex border px-2 text-xs font-semibold ${ props.selectedPreset === PRESETS.SIXTY_DAYS ? "text-white bg-black" : "text-gray-900 hover:bg-[#ebf4fa]"}`} preset={PRESETS.SIXTY_DAYS} onClick={presetClicked}>Last 60 days</span>
                <span className={`h-8 cursor-pointer rounded mx-2 items-center flex border px-2 text-xs font-semibold ${ props.selectedPreset === PRESETS.NINETY_DAYS ? "text-white bg-black" : "text-gray-900 hover:bg-[#ebf4fa]"}`} preset={PRESETS.NINETY_DAYS} onClick={presetClicked}>Last 90 days</span>
                <span className={`h-8 cursor-pointer rounded mx-2 items-center flex border px-2 text-xs font-semibold ${ props.selectedPreset === PRESETS.YTD ? "text-white bg-black" : "text-gray-900 hover:bg-[#ebf4fa]"}`} preset={PRESETS.YTD} onClick={presetClicked}>YTD</span>
            </div>
        </div>
    )
}

const DATESELECTOR_POSITION = {
    LEFT: "Left",
    RIGHT: "Right"
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

function DateSelector(props) {


    const prevMonthClicked = (event) => {
        event.stopPropagation()
        props.prevMonthClicked()
    }

    const nextMonthClicked = (event) => {
        event.stopPropagation()
        props.nextMonthClicked()
    }

    const dateClicked = (event) => {
        const target = event.target
        const day = target.textContent
        const date = new Date(props.year,props.month - 1,day)
        props.handleDateClicked(date)
    }

    function renderCalendar(firstDate = null, secondDate = null) {
        if (props.year === null | undefined || props.month === null | undefined) {
            return
        }
        const weeks = [[], [], [], [], [], []]
        
        const getDaysInMonth = (year, month) => {
            return new Date(year, month, 0).getDate();   
        }

        const currentYear = props.year;
        const currentMonth = props.month; 
        const days = getDaysInMonth(currentYear, currentMonth);

        /*const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1; 
        const days = getDaysInMonth(currentYear, currentMonth);*/

        /*const month = new Date(Date.UTC(props.year, props.month, 0));
        const days = month.getDate()*/
        let currentWeek = 0
        let isFirstDayOfTheWeek = true
        for (let i = 1; i <= days; i++) {
            const day = new Date(props.year, props.month-1, i)
            const dayOfTheWeek = day.getDay() - 1 === -1 ? 6 : day.getDay() - 1 // makes monday no. 0 and sunday 6

            if (isFirstDayOfTheWeek) {
                for (let j = 0; j < dayOfTheWeek; j++) {
                    weeks[currentWeek].push(
                        <span className=" w-[14.28%] text-center cursor-default pointer-events-none text-black"></span>
                    )
                }                
                isFirstDayOfTheWeek = false
            }

            let isSelected = false
            if (props.year !== null && props.month !== null && firstDate !== null) {
                const comparison = day.getTime() === firstDate.getTime()
                isSelected = comparison
            } 
            if (props.year !== null && props.month !== null && secondDate !== null) {
                const comparison = day.getTime() === secondDate.getTime()
                isSelected = isSelected || comparison
            }

            let shouldHighlight = false
            if (firstDate !== null && secondDate !== null) {
                if (day.getTime() > firstDate.getTime() && day.getTime() < secondDate.getTime()){
                    shouldHighlight = true
                }
            }
            let isNotAvailable = (day.getTime() < props.firstAvailableDate.getTime() || day.getTime() > props.lastAvailableDate.getTime())
            weeks[currentWeek].push(
                <span className={`w-[14.28%] h-full flex items-center justify-center rounded-full ${isNotAvailable ? "cursor-default pointer-events-none text-gray-300" : "cursor-pointer pointer-events-auto"} ${isSelected ? "bg-black text-white" : "text-black"} ${shouldHighlight ? "bg-[#ebf4fa] " : null}`} onClick={dateClicked}>{i}</span>
            )
            if (dayOfTheWeek === 6) {
                isFirstDayOfTheWeek = true
                currentWeek++
            }
        }

        for (let week of weeks) {
            if (week.length < 7) {
                const missingDays = 7 - week.length
                for (let i = 0; i < missingDays; i++) {
                    week.push(<span className=" w-[14.28%] h-full text-center cursor-default pointer-events-auto text-black"></span>)
                }
            }
        }
        const missingDays = 7 - weeks[5].length
        for (let i = 0; i < missingDays; i++) {
            weeks[5].push(<span className=" w-[14.28%] h-full text-center cursor-pointer pointer-events-auto text-black"></span>)
        }

        const calendar = (
            [...Array(6)].map((_, index) => {
            return <div key={index} className="flex h-[14.28%] justify-between items-center px-4 text-black font-bold text-[12px] select-none">
                {weeks[index].map((element, index)=> {
                    return <Fragment key={index}>{element}</Fragment>
                })}
            </div>
            })
        )
        return calendar
    }

    return (
        <div className="w-1/3 h-full flex flex-col bg-white p-2">
            <div className="h-12 flex text-black items-center px-2 py-4">
                <div className="flex-grow flex items-center justify-center relative font-bold select-none">
                    {monthNames[props.month-1]} {props.year}
                    {
                    props.position === DATESELECTOR_POSITION.LEFT ? 
                    <IoIosArrowBack size={22} className="absolute left-0 cursor-pointer pointer-events-auto" onClick={prevMonthClicked}/> :
                    <IoIosArrowForward size={22} className="absolute left-full -translate-x-full cursor-pointer pointer-events-auto" onClick={nextMonthClicked}/> 
                    }
                </div>
            </div>
            <div className="flex-grow flex-col flex">
                <div className="flex flex-col flex-grow justify-between">
                    <div className="flex justify-between items-center px-4 text-black font-bold text-[12px] h-[14.28%] select-none">
                        <span className=" w-[14.28%] text-center text-gray-400">Mo</span>
                        <span className=" w-[14.28%] text-center text-gray-400">Tu</span>
                        <span className=" w-[14.28%] text-center text-gray-400">We</span>
                        <span className=" w-[14.28%] text-center text-gray-400">Th</span>
                        <span className=" w-[14.28%] text-center text-gray-400">Fr</span>
                        <span className=" w-[14.28%] text-center text-gray-400">Sa</span>
                        <span className=" w-[14.28%] text-center text-gray-400">Su</span>
                    </div>
                    {   
                        renderCalendar(props.firstDate, props.secondDate)
                    }
                </div>
            </div>
        </div>
    )
}