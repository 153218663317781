import { useEffect, useState } from "react"
import { instance } from "../auth"
import { useNavigate } from "react-router-dom"
import { IoIosArrowBack } from "react-icons/io"
import { AiOutlineDownload, AiOutlinePoweroff } from "react-icons/ai"
import { ThreeDots } from "react-loader-spinner"
export default function MappingsDashboard(props) {
    
    const navigate = useNavigate()

    const [mappingsList, setMappingsList] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [selectedAccount, setSelectedAccount] = useState(null)

    const handleAccountSelection = (event) => {
        const target = event.target
        const accountIdString = target.getAttribute("accountId")
        const accountIdInt = parseInt(accountIdString)
        for (let account of mappingsList) {
            if (account.account_id === accountIdInt) {
                setSelectedAccount(account)
                setButtonIsActive(true)
                break
            }
        }

    }
    // loads the data from the router in the state
    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await instance.get("/mappings")
                const data = await response.data
                setMappingsList(data) // isLoading will be set to false in the useEffect that has mappingList as a dependency
            } catch (e) {
                if (e.response.status === 401) {
                    navigate("/")
                    return
                }
            }
        }
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    useEffect(()=> {
        if (mappingsList === null) {
            return
        }
        setIsLoading(false)
    }, [mappingsList])

    const backClicked = () => {
        navigate(-1)
    }

    const logOutClicked = async () => {
        const response = await instance.post("/auth/logout") 
        if (response.data.success) {
          window.location.reload()
        }
    }

    const [buttonIsActive, setButtonIsActive] = useState(false)

    const downloadCSVClicked = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        let csvHeaders = `"SKU","ASIN","Product name"\r\n`

        csvContent +=  csvHeaders

        selectedAccount.products.forEach(function(product) {
            let csvRow = `"${product.sku}","${product.asin}","${product.product_name}"\r\n`;
            csvContent += csvRow;
        });
        
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
    }

    return (
        <div className="bg-bgcolor h-[100vh] w-[100vw] flex flex-col">
            <div className='w-full h-14 bg-navcolor flex flex-shrink-0 justify-between px-3 items-center'>
                <div className="flex cursor-pointer items-center" onClick={backClicked}>
                    <IoIosArrowBack size={32} className="text-white cursor-pointer"/>
                    <span className="text-2xl text-white">Admin page</span>
                </div>
                <AiOutlinePoweroff size={32} className="text-white cursor-pointer" onClick={logOutClicked}/>
            </div>
            <div className='w-[100vw] pt-10 flex-col items-center flex-grow flex justify-top'>
                <div className='w-[80%] p-2 h-[4.25rem] flex gap-2 items-center justify-between'>
                    <div className='flex gap-2 items-center'>
                        <span className='text-3xl font-extrabold text-white p-2'>Products Mappings</span>
                        {isLoading === true ? 
                        <ThreeDots
                            height="50" 
                            width="50" 
                            radius="9"
                            color="#ffffff" 
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            />
                        : null}
                    </div>
                    <button onClick={downloadCSVClicked} className={`${buttonIsActive === false ? "text-gray-500 border-gray-500 cursor-not-allowed pointer-events-none" : "text-white border-white" } border rounded-lg flex h-12 px-2  font-bold justify-center items-center gap-1 select-none`}>
                        <AiOutlineDownload size={32} />
                        <span>Download CSV</span>
                    </button>
                </div>              
                <div className='w-[80%] h-[calc(100vh-12rem)] flex bg-white rounded-lg p-2 mb-5 gap-2'>
                    <div className="flex flex-col border-r-gray-300 border-r">
                        <table className="bg-white">
                            <thead>
                                <tr>
                                    <th className="h-10 whitespace-nowrap min-w-[20rem] w-[20rem] text-left text-bgcolor select-none">Brand name</th>
                                </tr>
                            </thead>
                        </table>
                        <div className="overflow-auto">
                            <table className="bg-white w-full">
                                <tbody>
                                    {
                                    mappingsList?.map((value) => {
                                        return (
                                            <tr className={`h-10 whitespace-nowrap min-w-[20rem] w-[20rem] text-left text-bgcolor hover:bg-sky-100 hover:cursor-pointer select-none ${selectedAccount !== null && selectedAccount.account_name === value.account_name ? "bg-sky-100" : null }`}>
                                                <td accountId={value.account_id}  onClick={handleAccountSelection}>{value.account_name}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <div className="overflow-auto w-[calc(100%-20rem)]">
                        <div className="flex flex-col h-full w-full ">
                            {
                                isLoading === true ? 
                                null :
                                selectedAccount === null ?
                                <div className="w-full h-full flex justify-center items-center">
                                    <span className=" text-lg text-gray-500">No brand selected</span>
                                </div> :
                                    <table className="bg-white w-full table-fixed">
                                        <thead className="sticky top-0 bg-white">
                                            <tr>
                                                <th className=" h-10 whitespace-nowrap min-w-[15rem] w-[15rem] text-left text-bgcolor">SKU</th>
                                                <th className=" h-10 whitespace-nowrap min-w-[10rem] w-[10rem] text-left text-bgcolor">ASIN</th>
                                                <th className=" h-10 whitespace-nowrap w-[calc(100%-25rem)] text-left text-bgcolor">Product name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                selectedAccount.products.map((product)=> {
                                                    return (
                                                        <tr>
                                                            <td className=" h-10 whitespace-nowrap min-w-[15rem] max-w-[15rem] w-[15rem] text-left text-bgcolor text-overflow overflow-hidden text-ellipsis">{product.sku}</td>
                                                            <td className=" h-10 whitespace-nowrap min-w-[10rem] w-[10rem] text-left text-bgcolor">{product.asin}</td>
                                                            <td className=" h-10 whitespace-nowrap min-w-[calc(100%-30rem)] max-w-[calc(100%-30rem)] w-[calc(100%-30rem)] text-left text-bgcolor text-overflow overflow-hidden text-ellipsis group">{product.product_name}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}