import { useEffect, useState } from "react";
import DashboardChart, {DASHBOARD_CHART_TYPE} from "./DashboardChart"

export default function PageViewsWidget(props) {
    useEffect(() => {
        if(props.salesData == null) {
            return
        }
        const pageViewsTotal = props.salesData.summary.page_views_total
        const pageViewsTotalPrevPeriod = props.salesData.summary.page_views_total_previous_period
        const pageViewsTotalPrevYear = props.salesData.summary.page_views_total_previous_year

        const deltaPrevPeriod = pageViewsTotalPrevPeriod == null ? null : (pageViewsTotal-pageViewsTotalPrevPeriod)/pageViewsTotalPrevPeriod
        const deltaPrevYear = pageViewsTotalPrevYear == null ? null : (pageViewsTotal-pageViewsTotalPrevYear)/pageViewsTotalPrevYear

        setPageViewsTotal(pageViewsTotal)
        setDeltaPrevPeriod(deltaPrevPeriod)
        setDeltaPrevYear(deltaPrevYear)
    }, [props.salesData])
    
    const [pageViewsTotal, setPageViewsTotal] = useState(null)
    const [deltaPrevYear, setDeltaPrevYear] = useState(null)
    const [deltaPrevPeriod, setDeltaPrevPeriod] = useState(null)

    const getDeltaString = (percentage) => {
        if (percentage == null) {
            return "--"
        }
        let sign = "+"
        if (percentage < 0){
            sign = "" // Minus sign is added by the toFixed method
        }
        return `${sign}${(percentage*100).toFixed(2)}%`
    }

    return (
        <div className={`bg-white ${props.fullWidth ? "w-full" : "w-1/3"} h-[300px] flex flex-col items-center justify-between p-2 rounded-md shadow-md`}>
            <div className="flex flex-col w-full">
                <span className='text-sm font-light'>PAGE VIEWS</span>
                <span className='text-4xl pb-2'>{pageViewsTotal ? pageViewsTotal.toLocaleString("en-US") : "--" }</span>
            </div>
            <div className="flex-grow w-full">
                {
                    props.salesData != null && props.salesData.summary.no_data === true ?
                    <div className="bg-gray-100 flex items-center justify-center h-full text-gray-400 font-semibold">No data to display</div> :
                    <DashboardChart salesData={props.salesData} type={DASHBOARD_CHART_TYPE.PAGEVIEWS} aggregation={props.aggregation}/>
                }
            </div>
            <div className='flex justify-between w-full px-2'>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-sm font-light'>Prev. Period</div>
                    <span className={`text-sm ${deltaPrevPeriod == null ? null : deltaPrevPeriod > 0 ? "text-green-600" : "text-red-600"}`}>{getDeltaString(deltaPrevPeriod)}</span>
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-sm font-light'>Prev. Year</div>
                    <span className={`text-sm ${deltaPrevYear == null ? null : deltaPrevYear > 0 ? "text-green-600" : "text-red-600"}`}>{getDeltaString(deltaPrevYear)}</span>
                </div>
            </div>
        </div>
    )
}