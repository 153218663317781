export default function SegmentedControl({options, handleOptionClick, selectedOption}) {
    const handleClick = (event) => {
        const selectedOption = event.target.getAttribute('value')
        handleOptionClick(selectedOption)
    }
    return (
        <div className="flex">
            {options.map((option, index) => {
                let style = "border-white border-t border-b px-2 py-1 w-20 text-center select-none"
                if (index === 0) {
                    style = style + " border-l rounded-l-md border-r"
                } else if (index === options.length - 1) {
                    style = style + " border-r rounded-r-md"
                } else {
                    style = style + " border-r"
                }

                if (option.value === selectedOption) {
                    style = style + " bg-white cursor-default events-none pointer-events-none"
                } else {
                    style = style + " text-white cursor-pointer hover:bg-white hover:bg-opacity-25"
                }

                return <div key={index} onClick={handleClick} className={`${style}`} value={option.value}>{option.label}</div>
            })}
        </div>
    )
}