import { useEffect, useState } from 'react';
import { IoIosArrowUp } from 'react-icons/io';
export default function BuyboxPercentageBySkuWidget(props) {
    
    const [topTen, setTopTen] = useState()
    const [others, setOthers] = useState()

    const [showOthers, setShowOthers] = useState(false)
    useEffect(()=>{
        if (props.salesData == null) {
            return
        }
        if (props.salesData != null && props.salesData.summary.no_data === true){
            return
        }
        const topTenArray = []
        const othersArray = []
        for (let i = 0; i < props.salesData.buybox_percentage_by_sku.length ; i++) {
            let element = props.salesData.buybox_percentage_by_sku[i]
            if (i <= 9) {
                topTenArray.push(element)
            } else {
                othersArray.push(element)
            }
            setTopTen(topTenArray)
            setOthers(othersArray)
        }
    }, [props.salesData])

    const formatPercentage = (value) => {
        if (value) {
            return `${value.toFixed(2)}%`            
        }
        return "N/A"

    }

    const getDeltaPercent = (newValue, prevValue) => {
        if (prevValue == null || prevValue === 0.0) {
            return "N/A"
        }
        if (newValue == null) {
            return "N/A"
        }
        return `${((newValue-prevValue)/prevValue*100).toFixed(2)}%`

    }

    const DeltaTd = (props) => {
        const deltaString = getDeltaPercent(props.newValue, props.prevValue)
        let cssString = ""
        if (deltaString.charAt(0) !== "N" && deltaString.charAt(0) !== "-" && deltaString !== "0.00%") {
            cssString = "text-green-500"
        }
        if (deltaString.charAt(0) !== "N" && deltaString.charAt(0) === "-") {
            cssString = "text-red-500"
        }
        return (
            <td className={`text-right ${!props.isOdd ? "bg-gray-100" : ""} text-xs px-4 font-semibold ${cssString}`}>{deltaString}</td>
        )
    }

    return (
        <div className="bg-white w-full flex flex-col items-start justify-between p-2 rounded-md shadow-md">
            <span className='text-sm font-light pb-2'>BUYBOX BY SKU</span>
            {
            props.salesData != null && props.salesData.summary.no_data === true ?
            <div className="bg-gray-100 flex items-center justify-center w-full h-40 text-gray-400 font-semibold">No data to display</div> :
            <div className='flex-grow flex-col flex w-full'>
                    <div className='font-bold text-white bg-bgcolor rounded-md text-xs mb-2 pl-1 select-none'>TOP 10</div>
                    <table className='table-auto w-full mb-2'>
                        <colgroup>
                            <col className='w-full'/>
                            <col className=''/>
                            <col className=''/>
                            <col className=''/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className='text-left text-gray-400 font-extrabold text-xs'>SKU</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4 whitespace-nowrap'>BB %</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4 whitespace-nowrap'>Prev. Period</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4 whitespace-nowrap'>Delta %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                topTen?.map((element, index)=>{
                                    let isOdd = true
                                    if (index % 2 === 0) {
                                        isOdd = false
                                    }
                                    return (
                                        <tr key={index}>
                                            <td className={`text-left  ${!isOdd ? "bg-gray-100" : ""} text-xs font-light`}><div className='inline-flex items-center gap-2'>{element.sku}</div></td>
                                            <td className={`text-right ${!isOdd ? "bg-gray-100" : ""} text-xs px-4 font-light`}>{formatPercentage(element.buybox_percentage)}</td>
                                            <td className={`text-right ${!isOdd ? "bg-gray-100" : ""} text-xs px-4 font-light`}>{formatPercentage(element.buybox_percentage_previous_period)}</td>
                                            <DeltaTd isOdd={isOdd} newValue={element.buybox_percentage} prevValue={element.buybox_percentage_previous_period} />
                                        </tr>
                                    )
                                })
                            }
                            
                        </tbody>
                    </table>
                    <div className='font-bold text-white bg-gray-400 rounded-md text-xs mb-2 px-1 flex justify-between'>
                        <span>OTHERS</span>
                        <IoIosArrowUp size={16} onClick={()=>{setShowOthers(!showOthers)}}  className={`cursor-pointer select-none transition ${ showOthers ? "rotate-180":""}`} />
                    </div>
                    {
                        showOthers ? 
                        <table className='table-auto w-full mb-2'>
                        <colgroup>
                            <col className='w-full'/>
                            <col className=''/>
                            <col className=''/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className='text-left text-gray-400 font-extrabold text-xs'>SKU</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4 whitespace-nowrap'>BB %</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4 whitespace-nowrap'>Prev. Period</th>
                                <th className='text-right text-gray-400 font-extrabold text-xs px-4 whitespace-nowrap'>Delta %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {   
                                others?.map((element, index)=>{
                                    let isOdd = true
                                    if (index % 2 === 0) {
                                        isOdd = false
                                    }
                                    return (
                                        <tr key={index}>
                                            <td className={`text-left  ${!isOdd ? "bg-gray-100" : ""} text-xs font-light`}>{element.sku}</td>
                                            <td className={`text-right ${!isOdd ? "bg-gray-100" : ""} text-xs px-4 font-light`}>{formatPercentage(element.buybox_percentage)}</td>
                                            <td className={`text-right ${!isOdd ? "bg-gray-100" : ""} text-xs px-4 font-light`}>{formatPercentage(element.buybox_percentage_previous_period)}</td>
                                            <DeltaTd isOdd={isOdd} newValue={element.buybox_percentage} prevValue={element.buybox_percentage_previous_period} />
                                        </tr>
                                    )
                                })
                            }
                            
                        </tbody>
                    </table>
                    : null
                    }
                    
                </div>
            }
        </div>
    )
}