import { useEffect, useRef, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart, LinearScale, PointElement, LineElement, Tooltip, Filler } from "chart.js";
import { AGGREGATION_LEVEL } from './Dashboard';

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement)
Chart.register(BarElement)
Chart.register(LineElement)
Chart.register(Tooltip)
Chart.register(Filler)

export const DASHBOARD_CHART_TYPE = {
  SALES: 0,
  UNITS: 1,
  BB_PERCENTAGE: 2,
  PAGEVIEWS: 3,
  SPONSORED_SALES: 4,
  SPONSORED_SALES_PERCENTAGE: 5
}
export default function DashboardChart(props) {

  const detailListKey = useRef()
  const amountsKey = useRef()
  const labelName = useRef()
  useEffect(()=> {
    if (props.salesData === null) {
      return
    }
    if (props.salesData != null && props.salesData.summary.no_data === true){
      return
    }

    if (props.type === DASHBOARD_CHART_TYPE.SALES) {
      amountsKey.current = "amount"
      detailListKey.current = "sales_detail_by_date"
      labelName.current = "sales"
    } else if (props.type === DASHBOARD_CHART_TYPE.UNITS) {
      amountsKey.current = "units"
      detailListKey.current = "units_by_date"
      labelName.current = "units"
    } else if (props.type === DASHBOARD_CHART_TYPE.BB_PERCENTAGE) {
      amountsKey.current = "buybox_percentage"
      detailListKey.current = "buybox_percentage_by_date"
      labelName.current = "buybox percentage"
    } else if (props.type === DASHBOARD_CHART_TYPE.PAGEVIEWS) {
      amountsKey.current = "page_views"
      detailListKey.current = "page_views_by_date"
      labelName.current = "page views"
    } else if (props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES) {
      amountsKey.current = "ads_sales"
      detailListKey.current = "sponsored_sales_detail_by_date"
      labelName.current = "sponsored sales"
    } else if (props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES_PERCENTAGE) {
      amountsKey.current = "ads_sales_percentage"
      detailListKey.current = "sponsored_sales_percentage_detail_by_date"
      labelName.current = "sponsored sales percentage"
    }
    /*
    amount:
    146465.55
    date:
    'Sun, 01 Jan 2023 00:00:00 GMT'
    */
    const labels = props.salesData[detailListKey.current]?.map((data) => {
      return data.date.slice(0,16)
    })
    const amounts = props.salesData[detailListKey.current]?.map((data) => {
      return data[amountsKey.current]
    })
    setSalesDates(labels)
    setSalesAmounts(amounts)
  }, [props.salesData, props.type])

  
  const [salesAmounts, setSalesAmounts] = useState(null)
  const [salesDates, setSalesDates] = useState(null)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const getAggregatedSalesDates = () => {
    if (props.aggregation === AGGREGATION_LEVEL.MONTHLY) {
      const monthsArray = []
      const monthsAndDates = {}

      for (let salesDate of salesDates) {
        const monthString = salesDate.slice(8)
        const dayString = salesDate.substring(5, 7)
        if (!(monthString in monthsAndDates)) {
          monthsArray.push(monthString)
          monthsAndDates[monthString] = []
        }
        let oldArray = monthsAndDates[monthString]
        oldArray.push(dayString)
      }

      let aggregatedDatesArray = []
      for (let month of monthsArray) {
        let firstDay = monthsAndDates[month][0]
        if (monthsAndDates[month].length === 1) {
          let singleDateString = `${firstDay}, ${month}`
          aggregatedDatesArray.push(singleDateString)
        } else {
          let lastDay = monthsAndDates[month].slice(-1)
          let aggregatedDatesString = `${firstDay} - ${lastDay}, ${month}`
          aggregatedDatesArray.push(aggregatedDatesString)
        }
        
      }
      return aggregatedDatesArray
    } else if (props.aggregation === AGGREGATION_LEVEL.WEEKLY) {
      const weeksArray = []

      let isFirstDay = true
      let firstDay = null
      
      for (let [index, salesDate] of salesDates.entries()) {
        if (isFirstDay === true) {
          firstDay = salesDate
          isFirstDay = false
        }
        let dayOfTheWeek = salesDate.substring(0, 3)
        
        if (dayOfTheWeek === "Sun") {
          if (firstDay === salesDate) {
            weeksArray.push(salesDate)
          } else {
            let aggregationString = `${firstDay} - ${salesDate}`
            weeksArray.push(aggregationString)
          }
          isFirstDay = true
          continue
        }

        if (index === salesDates.length - 1) {
          if (salesDate === firstDay) {
            weeksArray.push(salesDate)
          } else {
            let aggregationString = `${firstDay} - ${salesDate}`
            weeksArray.push(aggregationString)
          }
        }
      }

      return weeksArray
    } else if (props.aggregation === AGGREGATION_LEVEL.YEARLY) {
      let currentYear = null
      let years = []

      for (let date of salesDates) {
        let year  = date.substring(12,16)
        if (currentYear === null || year !== currentYear) {
          currentYear = year
          years.push(year)
        }
      }

      return years
    }
    
  }

  const getAggregatedSalesAmounts = () => {
    //data: salesAmounts,
    if (props.aggregation === AGGREGATION_LEVEL.MONTHLY) {
      const monthsArray = []
      
      const monthsAndAmounts = []
      
      for (let [index, salesDate] of salesDates.entries()) {
        const monthString = salesDate.slice(8)
        const amount = salesAmounts[index]
        if (!(monthsArray.includes(monthString))) {
          monthsArray.push(monthString)
          monthsAndAmounts[monthString] = []
        }
        
        let monthArray = monthsAndAmounts[monthString] 
        monthArray.push(amount)
        
      }

      let resultsArray = []
      for (let month of monthsArray) {
        let monthAmounts = monthsAndAmounts[month]
        let sum = 0.00
        for (let amount of monthAmounts) {
          sum = sum + amount
        }
        
        if (props.type === DASHBOARD_CHART_TYPE.BB_PERCENTAGE || props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES_PERCENTAGE) {
          resultsArray.push(sum/monthAmounts.length)
        } else {
          resultsArray.push(sum)
        }
      }
      
      
      return  resultsArray
    } else if (props.aggregation === AGGREGATION_LEVEL.WEEKLY) {
      let weeksArray = []

      let isFirstDay = true
      let firstDay = null
      
      let weeksAmount = []
      let weekAmount = 0
      let weekCount = 0

      for (let [index, salesDate] of salesDates.entries()) {
        if (isFirstDay === true) {
          firstDay = salesDate
          isFirstDay = false
        }
        let dayOfTheWeek = salesDate.substring(0, 3)
        
        let dayAmount = salesAmounts[index]
        weekAmount += dayAmount
        weekCount++

        if (dayOfTheWeek === "Sun") {
          if (firstDay === salesDate) {
            weeksArray.push(salesDate)
          } else {
            let aggregationString = `${firstDay} - ${salesDate}`
            weeksArray.push(aggregationString)
          }

          if (props.type === DASHBOARD_CHART_TYPE.BB_PERCENTAGE || props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES_PERCENTAGE) {
            weeksAmount.push(weekAmount/weekCount)
          } else {
            weeksAmount.push(weekAmount)
          }
          isFirstDay = true
          weekAmount = 0
          weekCount = 0
          continue
        }

        if (index === salesDates.length - 1) {
          if (salesDate === firstDay) {
            weeksArray.push(salesDate)
            weeksAmount.push(weekAmount)
          } else {
            let aggregationString = `${firstDay} - ${salesDate}`
            weeksArray.push(aggregationString)
            
            if (props.type === DASHBOARD_CHART_TYPE.BB_PERCENTAGE || props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES_PERCENTAGE) {
              weeksAmount.push(weekAmount/weekCount)
            } else {
              weeksAmount.push(weekAmount)
            }
          }
        }
      }

      return weeksAmount
    } else if (props.aggregation === AGGREGATION_LEVEL.YEARLY) {
      
      let currentYearTotal = 0.0
      let currentYear = salesDates[0].substring(12,16)
      let yearsAmounts = []
      let count = 0

      for (let [index, date] of salesDates.entries()) {
        let year  = date.substring(12,16)

        if (year !== currentYear) {
          // Se l'anno è cambiato pushiamo il vecchio totale e resettiamo i valori
          if (props.type === DASHBOARD_CHART_TYPE.BB_PERCENTAGE || props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES_PERCENTAGE) {
            yearsAmounts.push(currentYearTotal/count)
          } else {
            yearsAmounts.push(currentYearTotal)
          }
          currentYear = year
          count = 0
          currentYearTotal = 0.0
        }

        currentYearTotal += salesAmounts[index]
        count++

        if (index === salesDates.length - 1) {
          // Se è l'ultima data pushiamo il l'ultimo totale
          if (props.type === DASHBOARD_CHART_TYPE.BB_PERCENTAGE || props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES_PERCENTAGE) {
            yearsAmounts.push(currentYearTotal/count)
          } else {
            yearsAmounts.push(currentYearTotal)
          }
        }
        
      }

      return yearsAmounts
    }
  }

    return (
        <div className={`flex bg-white items-center justify-center w-full h-[calc(100%-0.2rem)]`}>
            
            {
              salesAmounts === null || salesDates === null ? 
              null :
              props.aggregation  === AGGREGATION_LEVEL.DAILY ?
              <Line
              datasetIdKey='id'
              options={{
                plugins:{
                  tooltip: {
                    intersect: false,
                    axis: 'x',
                    callbacks: {
                      label: (tooltipItem) => {
                        const label = tooltipItem.dataset.label
                        let data = null
                        if (props.type === DASHBOARD_CHART_TYPE.BB_PERCENTAGE || props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES_PERCENTAGE) {
                          data = tooltipItem.raw.toFixed(2)+"%"
                        } else if (props.type === DASHBOARD_CHART_TYPE.SALES || props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES) {
                          data = formatter.format(tooltipItem.raw)
                        } else {
                          data = tooltipItem.raw
                        }
                        return `${label}: ${data}`
                      }
                    }
                  }
                },
                responsive:true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    suggestedMin: Math.max(0, Math.min(...salesAmounts) - (Math.max(...salesAmounts) - Math.min(...salesAmounts))/10*2),
                    suggestedMax: Math.max(...salesAmounts) + (Math.max(...salesAmounts) - Math.min(...salesAmounts))/10*2,
                    /*ticks: {
                      display: false
                    },*/
                    offset: false

                  },
                  x: {
                    grid: {
                      color: "rgba(0,0,0,0)"
                    },
                    display: false,
                    offset: false
                  }
                }
              }}
              data={{
                labels: salesDates,
                datasets: [
                  {
                    id: 1,
                    label: labelName.current,
                    labels: salesDates,
                    data: salesAmounts,
                    borderColor: "rgba(35,66,88)",
                    pointRadius: salesDates.length > 30 ? 0: 1,
                    fill: true,
                    backgroundColor: "rgba(35,66,88,0.2)",
                    borderWidth:2
                  },
                ],
              }}
              />
              : props.aggregation === AGGREGATION_LEVEL.WEEKLY || props.aggregation === AGGREGATION_LEVEL.MONTHLY || props.aggregation === AGGREGATION_LEVEL.YEARLY ? 
              <Bar 
              datasetIdKey='id'
              options={{
                plugins:{
                  tooltip: {
                    intersect: false,
                    axis: 'x',
                    callbacks: {
                      label: (tooltipItem) => {
                        const label = tooltipItem.dataset.label
                        let data = null
                        if (props.type === DASHBOARD_CHART_TYPE.BB_PERCENTAGE || props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES_PERCENTAGE) {
                          data = tooltipItem.raw.toFixed(2)+"%"
                        } else if (props.type === DASHBOARD_CHART_TYPE.SALES || props.type === DASHBOARD_CHART_TYPE.SPONSORED_SALES) {
                          data = formatter.format(tooltipItem.raw)
                        } else {
                          data = tooltipItem.raw
                        }
                        return `${label}: ${data}`
                      }
                    }
                  }
                },
                responsive:true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    suggestedMin: Math.max(0, Math.min(...getAggregatedSalesAmounts()) - (Math.max(...getAggregatedSalesAmounts()) - Math.min(...getAggregatedSalesAmounts()))/10*2),
                    suggestedMax: Math.max(...getAggregatedSalesAmounts()) + (Math.max(...getAggregatedSalesAmounts()) - Math.min(...getAggregatedSalesAmounts()))/10*2,
                    /*ticks: {
                      display: false 80 116 153
                    },*/
                    offset: false,

                  },
                  x: {
                    grid: {
                      color: "rgba(0,0,0,0)"
                    },
                    display: false,
                    offset: true
                  }
                }
              }}
              data={
                {
                  labels: getAggregatedSalesDates(),
                  datasets: [
                    {
                      id: 1,
                      label: labelName.current,
                      labels: getAggregatedSalesDates(),
                      data: getAggregatedSalesAmounts(),
                      //borderColor: "#376994",
                      pointRadius: getAggregatedSalesDates().length > 30 ? 0: 1,
                      fill: true,
                      backgroundColor: "#376994",
                      borderWidth:0
                    },
                  ]

                }}/>

              : 
              null
            }
            
        </div>
    )
}